import { Component, Input, EventEmitter, Output, OnChanges, OnInit } from '@angular/core';
import { ConfirmModel } from '../classes/confirm-model';
import { CapcodConfirmConfig } from './capcod-confim-config';
import { CompatibilityService } from '../services/compatibility.service';

@Component({
  selector: 'capcod-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnChanges {
  // deprectaed use config instead
  @Input() actionLabel;
  @Input() withReason;
  @Input() title;
  @Input() message;
  @Input() objectId;
  // deprectaed use config instead
  @Input() config: CapcodConfirmConfig = new CapcodConfirmConfig();
  @Output() eventConfirmed = new EventEmitter<any>();
  model = new ConfirmModel();

  openValue: boolean;
  @Output() openChange = new EventEmitter();
  @Input()
  get open() {
    return this.openValue;
  }
  set open(val) {
    this.openValue = val;
    this.openChange.emit(this.openValue);
  }

  constructor(private compatibilityService: CompatibilityService) { }

  ngOnInit() {
    ['actionLabel', 'withReason', 'title', 'message', 'objectId'].forEach(el => {
      this.compatibilityService.setDeprecatedValueFromInput(this.config, this, el);
    });
  }

  ngOnChanges() {
  }

  public confirmDelete(event) {
    this.eventConfirmed.emit(this.config);
    this.open = false;
  }
}
