import { Buildable } from 'capcod-core';

export class GanttRowInputModel extends Buildable {
  unit: string;
  readonly = false;
  object: any;
  objectProperty: any;
  inputCssClass: string;
  alwaysDisplayUnit: any;

  constructor(partial: Partial<GanttRowInputModel>) {
    super(partial);
    super.build(partial);
  }
}
