import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, groupBy, mergeMap, toArray } from 'rxjs/operators';
import { Redirection } from '../model/redirection';
import { DexieInitService } from '../dexie/dexie-init.service';
import { Observable, of, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrackerService extends DexieInitService {
  public maxLoggedRedirections = 10;

  constructor(@Inject('env') protected environment: any, private router: Router) {
    super(environment.applicationName);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(x => {
        const currentUrl = window.location.href;
        setTimeout(() => {
          this.trackUrl(currentUrl);
        }, 1250);
      });
  }

  private cleanOldUrls() {
    this.redirections.count().then(x => {
      if (x > this.maxLoggedRedirections) {
        for (let i = this.maxLoggedRedirections; i < x; i++) {
          this.redirections.toArray().then(array => {
            const sortedArray = array.sort((a, b) => {
              return a.logdate.getTime() - b.logdate.getTime();
            });
            const toDelete = sortedArray[0];
            this.redirections.delete(toDelete.key);
          });
        }
      }
    });
  }

  public trackUrl(previousUrl: string) {
    if (previousUrl !== window.location.href) { return; }

    const url = this.router.url;
    const tags = document.getElementsByTagName('h1');
    if (tags && tags.length > 0) {
      setTimeout(() => {
        const h1tag = document.getElementsByTagName('h1')[0];
        const redirection = new Redirection(url, h1tag.innerHTML);
        this.redirections.add(redirection).then(success => {
        }).catch(error => {
        });
      });
    } else {
      console.warn(`cannot add URL to favorites because there is not h1 tag`);
    }
  }

  updateUrlIfChanged(id: string, h1tag: string) {
    // const currentH1 = document.getElementsByTagName('h1')[0].innerHTML;
    // setTimeout(() => {
    //   this.updateUrlIfChanged(id, h1tag);
    // }, 200);
  }


  public getMostVisisted(): Observable<any> {
    return new Observable((obs) => {
      this.redirections.toArray().then((array: any[]) => {
        let res = [];
        of(...array).pipe(
          groupBy((redirection: Redirection) => redirection.url),
          mergeMap(group => zip(of(group.key), group.pipe(toArray())))
        ).subscribe(result => {
          const redirections = result[1];
          const red: Redirection = redirections.sort((a, b) => {
            return a.logdate.getTime() - b.logdate.getTime();
          })[redirections.length - 1];
          const redirection = new Redirection(red.url, red.content);
          redirection.count = result[1].length;
          res.push(redirection);
        },
          error => {

          }, () => {
            res = res.sort(this.sortVisited());
            obs.next(res);
          });
      });
    });
  }

  private sortVisited(): (a: Redirection, b: Redirection) => number {
    return (a, b) => {
      return b.count - a.count;
    };
  }
}
