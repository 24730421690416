import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CapcodConfirmConfig } from '../../confirm/capcod-confim-config';
import { ListGeneric, CrudServiceGeneric, FieldEditorInformation } from 'capcod-core';
@Component({
  selector: 'capcod-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class SelectorComponent extends ListGeneric implements OnInit {
  openValue: boolean;
  @Input() title = '[title]';
  @Input() actionName = '[actionName]';
  @Input() confirmConfig: CapcodConfirmConfig;
  @Output() openChange = new EventEmitter();
  @Input()
  get open() {
    return this.openValue;
  }
  set open(val) {
    this.openValue = val;
    if (val === true) {
      this.reload(this.currentGetAllRequest);
    }
    this.openChange.emit(this.openValue);
  }
  @Input() service: CrudServiceGeneric;
  @Output() select = new EventEmitter<any>();
  objects: any[];
  selected = [];
  openConfirm = false;
  @Input() tableFeis: FieldEditorInformation[];

  ngOnInit() {
    super.ngOnInit();
  }

  afterLoad() {
    this.selected.forEach(el => {
      const currentobj = this.objects.find(x => x.id === el.id);
      if (currentobj) {
        this.selected[this.selected.indexOf(el)] = currentobj;
      } else {
        this.selected.splice(this.selected.indexOf(el), 1);
      }
    });

    if (!this.tableFeis) {
      this.tableFeis = this.schema.fieldEditorInfoAttributes.filter(x => x.tableDisplay);
    }
  }

  close() {
    this.open = false;
  }

  onAction() {
    if (this.confirmConfig) {
      this.openConfirm = true;
    } else {
      this.onSelect();
    }

  }

  onSelect() {
    this.select.emit(this.selected);
    this.selected = [];
    this.close();
  }
}
