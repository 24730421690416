import { Injectable } from '@angular/core';
import { FieldEditorInformation } from 'capcod-core';

@Injectable({
    providedIn: 'root'
})
export class CityService {

    handleCityAndZipHelper(object: any, feis: FieldEditorInformation[], changedFei: FieldEditorInformation) {
        const value = object[changedFei.name];
        if (!feis ||  !value || !value.id) { return; }
        const countryFei = feis.find(x => x.countryHelper);
        if (countryFei) {
            object[countryFei.name] = 'FRANCE';
        }
        if (changedFei.cityHelper) {
            object[changedFei.name] = value.name;
            const zc = feis.find(x => x.zipCodeHelper);
            if (zc) {
                object[zc.name] = value.zipCode;
            }
        } else if (changedFei.zipCodeHelper) {
            object[changedFei.name] = value.zipCode;
            const zc = feis.find(x => x.cityHelper);
            if (zc) {
                object[zc.name] = value.name;
            }
        }
    }
}
