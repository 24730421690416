import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Week } from 'capcod-core';
import { addWeeks, getISOWeek, getYear } from 'date-fns';
import { GenericGanttConfig } from '../../../classes/gantt/generic-gantt-config';

@Component({
  selector: 'capcod-week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss']
})
export class WeekPickerComponent implements OnInit {
  @Output() changeWeek = new EventEmitter<Week>();
  @Output() selectNbOfWeek = new EventEmitter<number>();
  @Input() showWeekNbSelector = false;
  @Input() config: GenericGanttConfig;

  currentWeek: Week;
  yearList: number[];
  selectYear: number;
  weekList: number[];
  selectWeek: number;

  numberOfWeeks = 3;
  numbersOfWeeks = [1, 3, 5, 7, 9, 14, 20];

  ngOnInit(): void {
    if (this.config) {
      this.numberOfWeeks = this.config.defaultSelectedWeek;
      this.numbersOfWeeks = this.config.weekSelectionChoices;
    }
    const toDay = new Date();
    const years: number[] = [];
    const weeks: number[] = [];

    for (let year = 2019; year <= toDay.getFullYear() + 1; year++) {
      years.push(year);
    }

    for (let week = 1; week <= 54; week++) {
      weeks.push(week);
    }

    this.yearList = years;
    this.weekList = weeks;
    this.selectYear = toDay.getFullYear();
    this.selectWeek = getISOWeek(toDay);
    this.setupDate(toDay);
  }

  goToNextWeek() {
    this.setupDate(addWeeks(this.currentWeek.firstDayOfWeek, 1));
  }

  goToPreviousWeek() {
    this.setupDate(addWeeks(this.currentWeek.firstDayOfWeek, -1));
  }

  setupDate(date: Date) {
    this.selectWeek = getISOWeek(date);
    this.selectYear = getYear(date);
    this.currentWeek = new Week(date);
    this.changeWeek.emit(this.currentWeek);
  }

  onWeekChange() {
    let date = this.getDateOfISOWeek(this.selectWeek, this.selectYear);
    this.selectYear = getYear(date);
    this.setupDate(date);
  }

  onWeekNumberSelection() {
    this.selectNbOfWeek.emit(this.numberOfWeeks);
    this.changeWeek.emit(this.currentWeek);
  }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }
}
