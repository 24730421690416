import { IGenericButtonStyle } from './IGenericButtonStyle';


export enum ClarityButtonShape {
    Standard = 'standard',
    Outline = 'outline'
}

export enum ClarityButtonLevel {
    Primary = 'primary',
    Success = 'success',
    Warning = 'warning',
    Danger = 'danger'
}
export class ClarityButtonStyle implements IGenericButtonStyle {
    constructor(private shap: ClarityButtonShape = ClarityButtonShape.Standard,
        private level: ClarityButtonLevel = ClarityButtonLevel.Primary) {
    }

    getCss() {
        let res = 'btn ';
        if (this.shap === ClarityButtonShape.Standard) {
            res += `btn-${this.level.toString().toLocaleLowerCase()}`;
        } else if (this.shap === ClarityButtonShape.Outline) {
            res += `btn-${this.level.toString().toLocaleLowerCase()}-outline`;
        }
        return res;
    }
}
