import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CompatibilityService {
    setDeprecatedValueFromInput(object: any, oldObject: any, propName: string) {
        const deprecatedInput = oldObject[propName];
        if (deprecatedInput !== null && deprecatedInput !== undefined) {
            object[propName] = deprecatedInput;
        }
    }
}
