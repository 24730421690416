import { OnInit } from '@angular/core';
import { CrudServiceGeneric } from './CrudServiceGeneric';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

export class DetailsGeneric implements OnInit {
  public object: any;
  protected id: string;
  public isAddModalVisible;
  public reloadSubject = new Subject();
  public deleteConfirm;
  public loading: boolean;
  public total: number;

  constructor(public service: CrudServiceGeneric, public route: ActivatedRoute, public location: Location = null) {
    this.route.params.subscribe(x => {
      this.id = x.id;
      this.load();
    });
  }

  reload() {
    this.reloadSubject.next();
  }

  goBack() {
    if (this.location) {
      this.location.back();
    } else {
      console.warn('add private location: Location in component constructor.');
    }
  }

  load() {
    this.loading = true;
    this.service.get(this.id).subscribe(x => {
      if (x) {
        this.object = x;
        this.isAddModalVisible = false;
        this.afterLoad();
      }
    });
  }

  afterLoad() {
    console.warn('afterload function is not defined');
    this.loading = false;
  }

  ngOnInit() {
    // this.load();
  }
}
