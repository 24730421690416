import { Component, OnInit, Input } from '@angular/core';
import { GanttCellModel } from '../../../classes/gantt/gantt-cell-model';
import { GanttRowDeadlineModel } from '../../../classes/gantt/gantt-row-deadline-model';
import { GanttRowSignpostModel } from '../../../classes/gantt/gantt-row-signpost-model';
import { isSameDay } from 'date-fns';

@Component({
  selector: 'capcod-generic-gantt-cell',
  templateUrl: './generic-gantt-cell.component.html',
  styleUrls: ['./generic-gantt-cell.component.scss']
})
export class GenericGanttCellComponent implements OnInit {
  @Input() gantCell: GanttCellModel;
  @Input() deadlineConfig: GanttRowDeadlineModel;
  @Input() signpostConfig: GanttRowSignpostModel;

  gradientStyle: string;
  style: any;
  class: any;
  showSignpost = false;

  constructor() { }

  ngOnInit(): void {
    if (!this.gantCell) { return; }
    this.gantCell.setDeadline(this.deadlineConfig?.deadline);
    this.class = this.gantCell.getCssClass();
    this.setStyle();
  }

  setStyle() {
    this.setGradientStyle(this.gantCell.lastDayProgressPercent);
    const isLastDayOfProgress = isSameDay(this.gantCell.cellDate, this.gantCell.progressEndDate) && this.gantCell.isInsidePlanning;
    this.style = { 'background': isLastDayOfProgress ? this.gradientStyle : '' };
  }

  setGradientStyle(progress: number) {
    this.gradientStyle = `linear-gradient(90deg, rgba(219, 33, 0, 1) ${progress}%, rgba(0, 114, 163, 1) ${progress}%)`;
  }

  toggleSignpost() {
    if (this.gantCell.isInsidePlanning) {
      this.showSignpost = !this.showSignpost;
    }
  }
}
