import { createPatch, Operation } from 'rfc6902';

export class JsonPatch {
  public generatePatch(a, destinationObject): any {
    const diff = createPatch(a, destinationObject);
    const finalPatch = diff.filter(x => x.path.split('/').length <= 2);
    const keys = diff.filter(x => x.path.split('/').length > 2);
    const nValues = [];
    keys.forEach((k: any) => {
      const splittedPath = k.path.split('/');
      const root = splittedPath[1];
      const path = splittedPath[2];

      if (splittedPath.length === 3) {
        if (!nValues[0]) {
          nValues.push({
            op: k.op,
            path: '/' + root,
            value: {}
          });
        }
        nValues[0].value[path] = k.value;
      }
    });

    Object.keys(destinationObject).forEach(key => {
      const src = a[key];
      let dest = destinationObject[key];
      if (src !== dest && !Array.isArray(dest)) {
        if (dest && dest.id) {
          dest = {
            id: dest.id
          };
        }

        const op: Operation = { op: 'replace', path: `/${key}`, value: dest };
        const olderOperation = finalPatch.find(x => x.path === op.path);
        if (olderOperation) {
          finalPatch.splice(finalPatch.indexOf(olderOperation), 1);
        }

        finalPatch.push(op);
      }
    });

    return finalPatch;
  }
}
