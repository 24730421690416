import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { DateService } from 'capcod-core';
import { GanttDateModel } from '../../../classes/gantt/gantt-date-model';

@Component({
  selector: 'capcod-generic-gantt-dates',
  templateUrl: './generic-gantt-dates.component.html',
  styleUrls: ['./generic-gantt-dates.component.scss']
})
export class GenericGanttDatesComponent implements OnInit, OnChanges {
  @Input() dates: Date[];
  @Input() currentDate: Date;
  @Input() fullSize = false;
  @Input() dateFormat = 'EEEEE';

  weekNumbers: number[];
  ganttDates: GanttDateModel[];

  constructor(private dateService: DateService) { }

  ngOnInit() {
  }

  ngOnChanges(changes): void {
    if (!changes.dates) { return; }

    this.weekNumbers = this.dateService.getWeekNumbers(this.dates[0], this.dates[this.dates.length - 1]);
    this.ganttDates = this.dates.map(x => new GanttDateModel({ date: x }));
  }

  toggleSignpost(date: any) {
    date.showSignpost = !date.showSignpost;
  }
}
