import { isAfter, isBefore, isSameDay, isValid, startOfDay } from 'date-fns';

export class GanttCellModel {
  // used :
  currentDate: Date;
  planningStartDate: Date;
  planningEndDate: Date;

  // don't know
  cellDate: Date;
  isCurrentDate: boolean;
  isDeadlineDate: boolean;
  // isDeadlineTomorrow: boolean;
  // isTomorrow: boolean;

  planningHoursNeeded: number;
  isInsidePlanning: boolean;
  isOutsidePlanning: boolean;

  progressStartDate: Date;
  progressEndDate: Date;
  progressHoursDone: number;
  lastDayProgressPercent: number;
  isInsideProgress: boolean;
  isOutsideProgress: boolean;

  displayProgression: boolean;

  constructor(init?: Partial<GanttCellModel>) {
    Object.assign(this, init);

    this.isCurrentDate = isSameDay(this.cellDate, this.currentDate);
    this.isOutsidePlanning = this.isOutside(this.cellDate, this.planningStartDate, this.planningEndDate);
    this.isInsidePlanning = !this.isOutsidePlanning;
    this.isOutsideProgress = this.isOutside(this.cellDate, this.progressStartDate, this.progressEndDate);
    this.isInsideProgress = !this.isOutsideProgress && this.displayProgression;
  }

  public setDeadline(deadline: Date) {
    this.isDeadlineDate = isSameDay(this.cellDate, deadline);
  }

  isOutside(date: Date, startDate: Date, endDate: Date) {
    const isUndefined = !date || !startDate || !endDate;
    const validDates = isValid(date) && isValid(startDate) && isValid(endDate);
    const isOutside = isBefore(startOfDay(date), startOfDay(startDate)) || isAfter(startOfDay(date), startOfDay(endDate));
    return isUndefined || isOutside || !validDates;
  }

  getCssClass() {
    return {
      'is-today': this.isCurrentDate,
      'date-inside': this.isInsidePlanning,
      'date-outside': this.isOutsidePlanning,
      'date-progress': this.isInsideProgress && this.isInsidePlanning,
    };
  }

  getStyle() {
    const gradientStyle = this.getGradientStyle(this.lastDayProgressPercent);
    const isLastDayOfProgress = isSameDay(this.cellDate, this.progressEndDate) && this.isInsidePlanning && this.isInsideProgress;
    const style = { 'background': isLastDayOfProgress ? gradientStyle : '' };
    return style;
  }

  getGradientStyle(progress: number) {
    return `linear-gradient(90deg, rgba(219, 33, 0, 1) ${progress}%, rgba(0, 114, 163, 1) ${progress}%)`;
  }
}
