import { Injectable, Inject } from '@angular/core';
import { ClarityHttpService } from './clarity-http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(@Inject('env') private env, private http: ClarityHttpService) {

  }

  me(): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/account/me`, { withCredentials: true });
  }

  setRoles(): Observable<any> {
    return new Observable((obs) => {
      this.me()
        .subscribe(me => {
          if (me && me.userRoles) {
            const roles = me.userRoles.map(ur => ur.role.name);
            localStorage.setItem('roles', roles);
          }

          obs.next();
        });
    });
  }

  claims(): Observable<any> {
    return this.http.get(`${this.env.apiUrl}/account/claims`, { withCredentials: true });
  }

  resetpassword(payload): Observable<any> {
    return this.http.post(`${this.env.apiUrl}/account/resetpassword`, payload);
  }
}
