import { CrudServiceGeneric } from '../CrudServiceGeneric';
import { DynamicCrudServiceInterface } from '../../services/DynamicCrudServiceInterface';

export class FieldEditorInformation {
  static primitiveTypes = ['String', 'Decimal', 'Nullable', 'DateTime', 'Boolean', 'Int32', 'Single', 'Double'];
  type: string;
  order: number;
  name: string;
  displayName: string;
  withTime: boolean;
  tableDisplay: boolean;
  readOnly: boolean;
  inCalculation: boolean;
  required: boolean;
  textArea: boolean;
  unit: string;
  isUrl; boolean;
  hidden: boolean;
  editOnly: boolean;
  possibleValues: any[];
  observableSource: any;
  manyToManyPath: string;
  lookupProperty: string;
  showModal: boolean;
  lockDirectAdd: boolean;
  lookupCollection: string;
  lookupReverse: string;
  lookupName: string;
  lookupObject: any;
  lookupDirectConf: any;
  service: DynamicCrudServiceInterface | CrudServiceGeneric;
  oneToOne: boolean;
  cityHelper: boolean;
  zipCodeHelper: boolean;
  countryHelper: boolean;
  togglePanel: boolean;
  dateWithTime: boolean;
  isRichText: boolean;
  tableEdit: any;
  isNullable: boolean;
  filterable: boolean;
  link: string;
  displayFunction: (object: any) => string;
  reload: boolean;
  sortName: string;

  getDisplayName() {
    return this.displayName == null ? this.name : this.displayName;
  }
}
