export class ListFormatters {

    public display(object) {
        if (!object) {
            return 'N/A';
        }
        let display = object.display;
        if (!display) {
            display = `Please define a "Display" property in type`;
        }
        return display;
    }
}
