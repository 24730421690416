export class EventLog {
  content: string;
  createdAt: Date;
  updatedAt: Date;
  done: boolean;
  taskName: string;
  totalSteps: number;
  step: number;
  id: string;
  failure: boolean;
}
