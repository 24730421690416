export class CapcodRequest {
    key: string;
    value: any;
    savedAt: Date;
    constructor(key: string, value: any, savedAt: Date) {
        this.key = key;
        this.value = value;
        this.savedAt = savedAt;
    }
}
