import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudServiceGeneric } from '../classes/CrudServiceGeneric';
import { ClarityHttpService } from './clarity-http.service';

const PRODURL = 'https://api.mail.capcod.cloud/api';
// const TESTURL = 'http://localhost:32797/api';

@Injectable({
  providedIn: 'root'
})
export class MailService extends CrudServiceGeneric {

  // baseUrl = TESTURL + '/capcodMail';
  baseUrl = PRODURL + '/capcodMail';

  constructor(@Inject('env') protected environment, protected http: ClarityHttpService) {
    super({
      // apiUrl: TESTURL,
      apiUrl: PRODURL,
      applicationName: 'mail'
    }, http, 'capcodMail');
  }

  getMailEvents(messageId: string, lastStatusOnly: boolean): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${messageId}/events/${lastStatusOnly}`);
  }
}
