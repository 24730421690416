import { FieldEditorInformation } from './FieldEditorInformation';
import { LoadableObject } from './LoadablObject';

export class ValidationModel<T extends LoadableObject> {
    errors: Error[];
    hasErrors: boolean;
    object: T;
}

export class Error {
    propName: string;
    attribute: FieldEditorInformation;
    type: ErrorType;
}

export enum ErrorType {
    Required,
    Uknown,
    Other,
    NotUnique,
}
