import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CapcodIssue } from '../classes/api/capcodIssue';
import { map } from 'rxjs/operators';
import { CrudServiceGeneric } from '../classes/CrudServiceGeneric';
import { ClarityHttpService } from './clarity-http.service';
import { ValidationModel } from '../classes/api/ValidationModel';
import { HttpParams } from '@angular/common/http';

const PRODURL = 'https://api.galio.capcod.cloud/api';
// const TESTURL = 'http://localhost:32771/api';

@Injectable({
  providedIn: 'root'
})
export class GalioService extends CrudServiceGeneric {

  // baseUrl = TESTURL + '/capcodIssue';
  baseUrl = PRODURL + '/capcodIssue';

  constructor(@Inject('env') protected environment, protected http: ClarityHttpService) {
    super({
      // apiUrl: TESTURL,
      apiUrl: PRODURL,
      applicationName: 'galio'
    }, http, 'capcodIssue');
  }

  add(capcodIssue: CapcodIssue): Observable<ValidationModel<any>> {
    return this.http.post(`${this.baseUrl}/createIssue`, capcodIssue).pipe(map((x: ValidationModel<any>) => x));
  }

  getByIssueKey(issueKey: string, clientIssuesOnly: boolean): Observable<CapcodIssue> {
    let params = new HttpParams();
    params = params.append('clientIssuesOnly', clientIssuesOnly.toString());
    return this.http.get<CapcodIssue>(`${this.baseUrl}/issue/${issueKey}`, {params: params});
  }

  getNumberOfTickets(projectKey: string, clientIssuesOnly: boolean, activeOnly: any) {
    let params = new HttpParams();
    params = params.append('projectKey', projectKey.toString());
    params = params.append('clientIssuesOnly', clientIssuesOnly.toString());
    params = params.append('activeOnly', activeOnly.toString());

    return this.http.get<number>(`${this.baseUrl}/issuesCount`, {params: params});
  }

  getAllByProjectKey(projectKey: string, clientIssuesOnly: boolean, activeOnly: any, from: number,
    to: number, size: number, current: number): Observable<CapcodIssue[]> {
    const term = null;
    const skip = from;
    const length = size;
    const orderBy = '';
    const reverse = false;
    const includes = null;

    let params = new HttpParams();
    params = params.append('activeOnly', activeOnly);
    params = params.append('term', term);
    params = params.append('skip', skip.toString());
    params = params.append('length', length.toString());
    params = params.append('orderBy', orderBy);
    params = params.append('reverse', reverse.toString());
    params = params.append('includes', includes);
    params = params.append('clientIssuesOnly', clientIssuesOnly.toString());

    return this.http.get<CapcodIssue[]>(`${this.baseUrl}/issues/${projectKey}`, {params: params});
  }
}
