export class CapcodIssue {
  issuer: string;
  projectKey: string;
  summary: string;
  description: string;
  issueType: string;
  url: string;
  key: string;
  status: string;

  constructor(projectKey: string, url: string, issuer: string) {
    this.projectKey = projectKey;
    this.url = url;
    this.issuer = issuer;
  }
}
