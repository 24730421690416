import { Component, OnInit, Input, Output, EventEmitter, Inject, OnChanges } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { IGenericButtonStyle } from '../../classes/IGenericButtonStyle';
import { ClarityButtonStyle } from '../../classes/ClarityButtonStyle';
import { ConfirmModel } from '../../classes/confirm-model';
import {
  JsonPatch, CrudServiceGeneric, FieldEditorInformation, ValueChangedModel, ListFormatters, Schema,
  ClarityHttpService, FeiService, ErrorParser, ValidationModel, CoreService
} from 'capcod-core';
import { EditMode } from '../../enums/EditMode';
import { Subject, Observable } from 'rxjs';
import { ObjectEditorService } from '../../services/object-editor.service';
import { CSharpTypes } from '../../classes/CSharpTypes';
import { CapcodConfirmConfig } from '../../confirm/capcod-confim-config';


@Component({
  selector: 'capcod-object-editor',
  templateUrl: './object-editor.component.html',
  styleUrls: ['./object-editor.component.scss']
})
export class ObjectEditorComponent implements OnInit, OnChanges {
  objectValue: any;
  @Output() objectChange = new EventEmitter();
  @Input()
  get object() {
    return this.objectValue;
  }
  set object(val) {
    this.objectValue = val;
    this.objectChange.emit(this.objectValue);
  }

  sendingValue: ClrLoadingState = ClrLoadingState.DEFAULT;
  @Output() sendingChange = new EventEmitter();
  @Input()
  get sending() {
    return this.sendingValue;
  }
  set sending(val) {
    this.sendingValue = val;
    this.sendingChange.emit(this.sendingValue);
  }
  @Input() service: CrudServiceGeneric;
  @Input() lock = false;
  @Input() inCard = true;
  @Input() autoCalculate = true;
  @Input() canArchive = true;
  @Input() canSave = true;
  @Input() title = 'Edition';
  @Input() saveTitle = 'Enregister';
  @Input() successMessage = 'L\'object a bien été mis à jour.';
  @Input() subPath = '';
  @Input() saveButtonStyle: IGenericButtonStyle = new ClarityButtonStyle();
  @Input() directAdd = true;
  @Input() excludeProperties = [];
  @Input() canDelete = true;
  @Input() feis: FieldEditorInformation[];
  @Input() allowRedirect = false;
  @Input() redirectUrl: string[];
  @Input() validationSubject: Subject<any>;
  @Input() confirmConfig = new CapcodConfirmConfig();
  @Input() redirectAfterDelete = true;
  @Output() objectAdded = new EventEmitter<any>();
  @Output() objectUpdated = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<ValueChangedModel<any>>();
  @Output() objectDeleted = new EventEmitter<any>();

  inputs: any[] = [];
  formatters = ListFormatters;
  clone;
  updateSubject = new Subject();
  completeValue = '';
  textEditTypes = [CSharpTypes.String, CSharpTypes.Decimal, CSharpTypes.Single];
  injectedValue: any;
  defaultValue: any;
  schema: Schema;
  opendelete = false;
  groups: any[];
  mode = EditMode.Add;
  snoStyle = 'material';
  confirmReady = false;
  objectEditorService: ObjectEditorService;
  autoSave = false;
  autoSaveReady = false;

  constructor(private snotifyService: SnotifyService, @Inject('env') protected environment, protected http: ClarityHttpService,
    private route: ActivatedRoute, private router: Router, private feiService: FeiService, private coreService: CoreService) {
    if (environment.snotifyStyle) {
      this.snoStyle = environment.snotifyStyle;
    }

    if (environment.autoSave === undefined) {
      console.warn(`autoSave property isn't set in environnement, default value is false`);
    } else {
      this.autoSave = environment.autoSave;
    }
  }

  public prepareDeleteOrArchive() {
    this.opendelete = true;
  }

  public deleteOrAchive(event: ConfirmModel) {
    if (this.isArchive()) {
      this.service.archive(event.objectId, event.reason).subscribe(x => {
        this.object = x;
        this.objectDeleted.emit(this.object);
      });
    } else {
      this.service.hardDelete(event.objectId).subscribe(x => {
        this.objectDeleted.emit(event);
        if (this.redirectAfterDelete) {
          this.router.navigate(['../../'], { relativeTo: this.route });
        }
      });
    }
  }

  public unarchive() {
    this.service.unarchive(this.object.id).subscribe(x => {
      this.object = x;
    });
  }

  public isArchive() {
    return this.schema && this.schema.interface === 'IArchiveObject';
  }

  ngOnChanges(changes) {
    if (changes.object && changes.object.currentValue) {
      this.copyObjectInLocal(changes.object.currentValue);
    }

    if (this.service) {
      this.loadSchema();
    }
  }

  private loadSchema() {
    if (!this.schema) {
      this.service.getSchema().subscribe((schema: Schema) => {
        schema.fieldEditorInfoAttributes = schema.fieldEditorInfoAttributes
          .filter(x => this.excludeProperties.indexOf(x.name) === -1);
        this.schema = schema;
        this.whenSchemaSet();
        if (!this.feis) {
          this.feis = this.schema.fieldEditorInfoAttributes;
        }


        this.loadDefault().subscribe(res => {
          this.objectEditorService = new ObjectEditorService(this.schema, this.defaultValue, this.mode);
          this.prepareObject();
        });
      });
    } else {
      this.whenSchemaSet();
    }
  }

  private whenSchemaSet() {
    this.groups = this.schema.groupInformation;
    if (this.confirmConfig) {
      if (this.object && this.object.id) {
        this.confirmConfig.objectId = this.object.id;
      }
      this.confirmConfig.withReason = this.isArchive();
    }

    this.confirmReady = true;
  }

  private loadDefault(): Observable<any> {
    return new Observable((obs) => {
      if (!this.defaultValue && this.service) {
        this.service.default().subscribe(x => {
          delete x.id;
          this.defaultValue = x;
          this.prepareObject();
          obs.next();
        });
      } else {
        this.prepareObject();
        obs.next();
      }
    });
  }

  private prepareObject() {
    if (this.objectEditorService) {
      this.object = this.objectEditorService.prepareObject(this.injectedValue);
    }
  }

  ngOnInit() {
    this.mode = this.object ? EditMode.Edit : EditMode.Add;
    if (this.validationSubject) {
      this.validationSubject.subscribe(x => {
        this.addOrUpdate();
      });
    }

    this.autoSaveReady = true;
  }

  public calculate(changedFei: FieldEditorInformation) {
    this.valueChanged.emit({ object: this.object, fei: changedFei });
    this.calculateOnce(changedFei);
    const lastFei = this.feiService.getLastVisible(this.feis);

    if (this.autoSave && lastFei.name === changedFei.name) {
      this.addOrUpdate();
    }

    this.updateDependentSources(changedFei);
  }


  private updateDependentSources(changedFei: FieldEditorInformation) {
    const name = changedFei.name;
    const dependent = this.feis.find(x => x.lookupProperty && x.lookupProperty.toLocaleUpperCase() === name.toLocaleUpperCase());
    if (dependent) {
      dependent.observableSource = null;
      this.updateSubject.next();
    }
  }

  private calculateOnce(changedFei: FieldEditorInformation) {
    if (this.autoCalculate) {
      const inCalculationFields = this.feis.filter(f => f.inCalculation);
      if (inCalculationFields.length === 0 || inCalculationFields.find(x => x.name === changedFei.name)) {
        this.updateCalculatedFields(this.object);
      }
      this.updateSubject.next();
    }
  }

  private updateCalculatedFields(obj) {
    this.service.calculate(this.object).subscribe(x => {
      if (this.feis) {
        this.feis.filter(f => f.readOnly).forEach(fei => {
          if (x && obj) {
            obj[fei.name] = x[fei.name];
          }
        });
      }
    });
  }

  public addOrUpdate() {
    this.sending = ClrLoadingState.LOADING;

    if (this.autoSaveReady) {
      if (this.object.id) {
        this.update();
      } else {
        this.add();
      }
    }
  }

  private add() {
    this.service.add(this.object).subscribe(modelValidation => {
      if (modelValidation.hasErrors) {
        ErrorParser.extractErrorMessages(modelValidation).forEach(message => {
          this.snotifyService.error(message);
        });
      } else {
        if (this.allowRedirect) {
          if (this.redirectUrl) {
            this.router.navigate(this.redirectUrl);
          } else {
            this.router.navigate(['details', modelValidation.object.id], { relativeTo: this.route });
          }
        } else {
          this.reloadAfterAdd(modelValidation);
        }
      }
      this.sending = ClrLoadingState.SUCCESS;
    }, error => {
      this.snotifyService.error(`Une erreur s'est produite.`);
      this.sending = ClrLoadingState.ERROR;
    });
  }


  private reloadAfterAdd(modelValidation: ValidationModel<any>) {
    this.snotifyService.success(`Ajout effectué`);
    this.defaultValue = null;
    this.loadDefault().subscribe(() => {
      if (modelValidation.hasErrors) {
        this.objectAdded.emit(modelValidation.object);
      } else {
        this.service.get(modelValidation.object.id).subscribe(x => {
          this.objectAdded.emit(x);
        });
      }

      this.prepareObject();
    });
  }

  private refresh() {
    const objId = this.object.id;
    // this.object = null;
    let lastValue = null;
    this.service.get(objId).subscribe(
      modified => {
        lastValue = modified;
      },
      error => { }
      , () => {
        this.object = Object.assign({}, lastValue);
        this.copyObjectInLocal(this.object);
        this.objectAdded.emit(this.object);
      });
  }

  private update() {
    const p = new JsonPatch();
    const manyToManyProperties = this.feis.filter(x => x.manyToManyPath);
    manyToManyProperties.forEach(prop => {
      this.clone[prop.name] = this.object[prop.name];
    });
    const patch = p.generatePatch(this.clone, this.object);
    const id = this.object.id;
    this.service.patch(id, patch)
      .subscribe((res) => {
        this.refresh();
        this.snotifyService.success(`Modification effectuée`);
        this.sending = ClrLoadingState.SUCCESS;
        this.objectUpdated.emit(res);
      },
        () => {
          this.snotifyService.error(`Une erreur s'est produite lors de la mise à jour.`);
          this.sending = ClrLoadingState.ERROR;

        });
  }

  private copyObjectInLocal(obj) {
    if (!obj) { return; }

    if (obj.id) {
      this.mode = EditMode.Edit;
    }
    this.injectedValue = Object.assign({}, obj);
    this.clone = Object.assign({}, obj);
  }
}
