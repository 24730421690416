import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { tap } from 'rxjs/operators';

@Injectable()

export class ClarityHttpInterceptor implements HttpInterceptor {
  private azureUrl = 'azure';
  private azureTokenItem = 'msal.idtoken';
  private defaultTokenItem = 'token';

  constructor(private router: Router, private snotifyService: SnotifyService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    window.localStorage.setItem('prevUrl', window.location.href);
    if (!request.url) {
      const req = request.clone({
        url: 'rien'
      });
      console.log('bypass request', req);
      return next.handle(req);
    }

    request = this.addTokenToHeaders(request);
    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

          }
        }, (err: any) => {
          window.localStorage.setItem('prevUrl', window.location.href);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              this.router.navigate(['/login']);
            } else if (err.status === 404) {
              console.error(`Url not found`);
            } else if (err.status >= 400 && err.status < 500) {
              this.snotifyService.error(err.error);
            } else if (err.status === 500) {
              this.snotifyService.error('Une erreur interne est survenue');
            }
          }
          return throwError(err);
        })
      );
  }

  addTokenToHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let headers = {};
    if (request.url) {
      const tokenItem = request.url.includes(this.azureUrl) ? this.azureTokenItem : this.defaultTokenItem;
      headers = {
        Authorization: `Bearer ${localStorage.getItem(tokenItem)}`,
        'Current-culture': navigator.language,
        'Current-tz-offset': new Date().getTimezoneOffset().toString()
      };
    }
    return request.clone({
      setHeaders: headers
    });
  }
}
