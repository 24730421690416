import { Inject, Injectable } from '@angular/core';
import { FieldEditorInformation } from '../../classes/api/FieldEditorInformation';
import { Observable } from 'rxjs';
import { DexieInitService } from '../../dexie/dexie-init.service';
import { CapcodRequest } from './CapcodRequest';

@Injectable({
    providedIn: 'root'
})
export class CachingService extends DexieInitService {

    constructor(@Inject('env') protected environment: any) {
        super(environment.applicationName);
        this.version(1).stores({
            requests: 'key'
        });
    }

    cache(controller: string, request: string, value: any): any {
        const key = this.getKey(controller, request);
        const capcodRequest = new CapcodRequest(key, value, new Date());
        this.requests.get(key).then(current => {
            if (current) {
                this.requests.delete(key).then(() => {
                    this.requests.add(capcodRequest);
                });
            } else {
                if (capcodRequest.value.fieldEditorInfoAttributes) {
                    capcodRequest.value.fieldEditorInfoAttributes.forEach((fei: FieldEditorInformation) => {
                        delete fei.service;
                    });
                }
                this.requests.add(capcodRequest);
            }
        });
    }

    cacheObs(controller: string, request: string, value: any): Observable<any> {
        return new Observable((obs) => {
            const key = this.getKey(controller, request);
            const capcodRequest = new CapcodRequest(key, value, new Date());
            this.requests.get(key).then(current => {
                if (current) {
                    this.requests.delete(key).then(() => {
                        this.requests.add(capcodRequest);
                    });
                } else {
                    if (capcodRequest && capcodRequest.value.fieldEditorInfoAttributes) {
                        capcodRequest.value.fieldEditorInfoAttributes.forEach((fei: FieldEditorInformation) => {
                            delete fei.service;
                        });
                    }
                    this.requests.add(capcodRequest).then(() => {
                        obs.next();
                        obs.complete();
                    });
                }
            }).catch((reason) => {
                console.error('Failed to get data in store ', reason);
                this.requests.add(capcodRequest).then(() => {
                    obs.next();
                    obs.complete();
                });
            });
        });
    }

    remove(controller, request) {
        const key = this.getKey(controller, request);
        this.requests.delete(key).then(() => {
            // this.requests.add(capcodRequest);
        });
    }

    get(controller: string, request: string) {
        const key = this.getKey(controller, request);
        return this.requests.get(key);
    }

    getKey(controller: string, request: string) {
        return `${controller}.${request}`;
    }
}


