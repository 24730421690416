import { Buildable } from 'capcod-core';
import { CustomSubjectMethod } from '../custom-subject-method';

export class GanttRowDropDownModel extends Buildable {
    buttonLabel: string;
    customMethods: CustomSubjectMethod[];

    buttonClass = 'btn btn-secondary';
    forceButtonDropDown = true;

    constructor(partial: Partial<GanttRowDropDownModel>) {
        super(partial);
        super.build(partial);
    }
}
