import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {
  ClarityHttpService, CrudServiceInterface,
  DynamicCrudServiceGeneric, FieldEditorInformation,
  ServiceProvider, ValueChangedModel
} from 'capcod-core';
import { Subject } from 'rxjs';
import { CustomMethod } from '../../classes/custom-method';
import { ViewMode } from '../../enums/ViewMode';

@Component({
  selector: 'capcod-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() key: string;
  @Input() path: string;
  @Input() lock = false;
  @Input() service: CrudServiceInterface;
  @Input() title = 'objects';
  @Input() modalTitle = 'Modification';
  @Input() inCard = true;
  @Input() canDelete = true;
  @Input() lockEdit = false;
  @Input() lockDelete = false;
  @Input() mode = ViewMode.Card;
  @Input() excludeProperties = [];
  @Input() allowViewSwitch = true;
  @Input() reloadSubject: Subject<any>;
  @Output() valueChanged = new EventEmitter<ValueChangedModel<any>>();
  @Output() triggerEdit = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();
  @Output() elementUpdated = new EventEmitter<any>();
  @Input() customMethods: CustomMethod[];
  @Input() feis: FieldEditorInformation[];
  @Input() redirectAfterDelete = true;
  keys: string[];
  items = [];
  total = 0;
  schema: any;
  showModal = false;
  currentItem: any;
  itemService: CrudServiceInterface;
  toDeleteId: any;
  openDeleteModal = false;
  tablePropertieNames: FieldEditorInformation[];
  loading = false;
  collectionService: CrudServiceInterface;
  inlineTableActions = false;
  showOptions = false;

  constructor(@Inject('env') protected environment, protected http: ClarityHttpService, private serviceProvider: ServiceProvider) {
  }

  loadCollection() {
    if (!this.excludeProperties) {
      this.excludeProperties = [];
    }

    this.inlineTableActions = this.environment.inlineTableActions;

    this.service.getCollection(this.key, this.path).subscribe(x => {
      this.loading = true;
      const data = x.result;
      this.schema = x.schema;
      if (!this.feis) {
        this.feis = this.schema.fieldEditorInfoAttributes;
      }
      this.collectionService = this.serviceProvider.getInstance(this.schema.typeName);
      this.tablePropertieNames = this.feis.filter(fei => fei.tableDisplay && this.excludeProperties.indexOf(fei.name) === -1);
      const service = new DynamicCrudServiceGeneric(this.environment, this.http);
      service.setControllerName(this.schema.typeName);
      this.itemService = service;

      if (Array.isArray(data)) {
        this.items = data;
      } else {
        this.items[0] = data;
      }

      if (this.items[0]) {
        this.keys = Object.keys(this.items[0]);
      }
      this.openDeleteModal = false;
      this.showModal = false;
      this.loading = false;
    });
  }

  ngOnInit() {
    this.loadCollection();
    this.reloadSubject.subscribe(x => {
      this.loadCollection();
    });
  }

  onEdit(item) {
    this.itemService.get(item.id).subscribe(res => {
      this.currentItem = res;
      this.triggerEdit.emit(item);
      this.showModal = true;
    });
  }

  onDelete(item) {
    this.openDeleteModal = true;
    this.currentItem = item;
  }

  delete() {
    this.itemService.delete(this.currentItem.id).subscribe(x => {
      this.loadCollection();
      this.elementDeleted.emit(this.currentItem);
    });
  }

  closeModal() {
    this.showModal = false;
  }

  onValueChanged($event) {
    this.valueChanged.emit($event);
  }

  onObjectAdded(event) {
    this.valueChanged.emit(event);
    this.loadCollection();
  }

  onMouseEnter(index: number) {
    this.items[index].showOptions = true;
  }

  onMouseLeave(index: number) {
    this.items[index].showOptions = false;
  }
}
