// import { Schema } from '../../../capcod-core/src/public_api';
import { Schema } from 'capcod-core';
import { EditMode } from '../enums/EditMode';

export class ObjectEditorService {
  constructor(private schema: Schema, private defaultValue: any, private mode: EditMode) {

  }

  prepareObject(injectedValue): any {
    if (this.schema && this.defaultValue) {
      if (this.mode === EditMode.Edit) {
        const nValue = Object.assign({}, this.defaultValue);
        Object.keys(injectedValue).forEach(key => {
          nValue[key] = injectedValue[key];
        });
        return this.prepareForm(nValue);
      } else {
        return this.prepareForm(Object.assign({}, this.defaultValue));
      }
    }

    return null;
  }

  private prepareForm(object): any {
    Object.keys(object).forEach(k => {
      object[k] = this.reindexTable(object[k]);
    });
    this.copyObjectInLocal(object);
    return object;
  }

  private copyObjectInLocal(obj) {
    if (obj.id) {
      this.mode = EditMode.Edit;
    }
  }

  public reindexTable(table) {
    if (table && Array.isArray(table) && table.length > 0) {
      const res = [];

      for (let i = 0; i < table.length; i++) {
        const el = table[i];
        el.index = i;
        res.push(el);
      }

      res.sort(function (a, b) {
        return a.display - b.display;
      });
      return res;
    }

    return table;
  }
}
