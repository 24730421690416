import { Buildable } from 'capcod-core';
import { GanttRowDeadlineModel } from './gantt-row-deadline-model';
import { GanttRowDropDownModel } from './gantt-row-dropdown-model';
import { GanttRowInputModel } from './gantt-row-input-model';
import { GanttRowSignpostModel } from './gantt-row-signpost-model';

export class GanttRowModel extends Buildable {
  display: string;
  start: Date;
  end: Date;
  link?: string[];
  object: any;
  displayCssClass: string[];

  dropdownConfig: GanttRowDropDownModel;
  rowOptionsConfig: GanttRowDropDownModel;
  inputConfig: GanttRowInputModel;
  deadlineConfig: GanttRowDeadlineModel;
  signpostConfig: GanttRowSignpostModel = new GanttRowSignpostModel({});

  progressStart: Date;
  progressEnd: Date;
  lastDayProgressPercent: number;
  displayProgression: boolean;

  readOnly = false;

  constructor(partial: Partial<GanttRowModel>) {
    super(partial);
    this.build(partial);
  }

  build(partial: Partial<Buildable>) {
    const dateProperties = ['start', 'end'];
    Object.keys(partial).forEach(key => {
      if (dateProperties.includes(key)) {
        if (partial[key]) {
          this[key] = new Date(partial[key]);
        }
      } else if (key === 'dropdownConfig' || key === 'inputConfig' || key === 'deadlineConfig'
        || key === 'signpostConfig') {
        this[key] = partial[key];
        this[key].build(partial[key]);
      } else {
        this[key] = partial[key];
      }
    });
  }
}
