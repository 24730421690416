import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { TrackerService } from '../services/tracker.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceProvider } from '../services/ServiceProvider';
import { ClarityHttpInterceptor } from '../classes/interceptors/ClarityHttpInterceptor';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([])
  ],
  declarations: [
    OrderByPipe
  ],
  exports: [
    OrderByPipe
  ],
  providers: [
    ServiceProvider
  ],
})
export class CapcodCoreModule {
  constructor(private service: TrackerService) {

  }

  public static forRoot(environment: any): ModuleWithProviders {


    return {
      ngModule: CapcodCoreModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ClarityHttpInterceptor,
          multi: true
        }
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: ClarityHttpInterceptor,
        //   multi: true
        // }
      ]
    };
  }
}
