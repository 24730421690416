import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TrackerService } from 'capcod-core';
import { Redirection } from 'capcod-core';
// import { Redirection, TrackerService } from 'capcod-core';

@Component({
  selector: 'capcod-most-visited',
  templateUrl: './most-visited.component.html',
  styleUrls: ['./most-visited.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MostVisitedComponent implements OnInit {
  mostVisited: Redirection[];

    constructor(private tracker: TrackerService) {
    tracker.getMostVisisted().subscribe(x => {
      this.mostVisited = x.filter(redirection => redirection.content !== 'Les plus visités');
    });
  }

  ngOnInit(): void {
  }
}
