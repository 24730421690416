import { Buildable } from 'capcod-core';

export class GanttRowDeadlineModel extends Buildable {
  deadline: Date;
  readonly = false;
  inputCssClass: string[];

  constructor(partial: Partial<GanttRowDeadlineModel>) {
    super(partial);
    super.build(partial);
  }


  build(partial: Partial<Buildable>) {
    const dateProperties = ['deadline'];
    Object.keys(partial).forEach(key => {
      if (dateProperties.includes(key)) {
        this[key] = new Date(partial[key]);
      } else {
        this[key] = partial[key];
      }
    });
  }
}
