import { endOfWeek, getISOWeek, getYear, startOfWeek } from 'date-fns';
import { DateService } from '../../services/date.service';

export class Week {
  weekNumber: number;
  year: number;
  firstDayOfWeek: Date;
  lastDayOfWeek: Date;

  constructor(date: Date) {
    this.weekNumber = getISOWeek(date);
    this.year = getYear(date);
    this.firstDayOfWeek = startOfWeek(date, DateService.options);
    this.lastDayOfWeek = endOfWeek(date, DateService.options);
  }
}
