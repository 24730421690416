import { Component, OnInit, Input } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { ClrLoadingState } from '@clr/angular';
import { MailService } from 'capcod-core';

@Component({
  selector: 'capcod-follow-mail-status',
  templateUrl: './follow-mail-status.component.html',
  styleUrls: ['./follow-mail-status.component.scss']
})
export class FollowMailStatusComponent implements OnInit {
  @Input() messageId = null;
  @Input() lastStatusOnly = false;

  status: any;
  loading = ClrLoadingState.DEFAULT;
  constructor(private mailService: MailService, private snotifyService: SnotifyService) { }

  ngOnInit() {
    this.load();
  }

  private load() {
    if (this.messageId) {
      this.loading = ClrLoadingState.LOADING;
      this.mailService.getMailEvents(this.messageId, this.lastStatusOnly).subscribe({
        next: (data) => {
          this.status = data;
          this.loading = ClrLoadingState.SUCCESS;
        }, error: (err) => {
          this.snotifyService.error(`Erreur lors de la récupération du status du mail ${this.messageId}. Erreur : ${err}`);
          this.loading = ClrLoadingState.ERROR;
        }
      });
    } else {
      this.snotifyService.error('Suivi du mail impossible, le mailId est null.');
    }
  }

  refresh() {
    this.load();
  }
}
