import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClarityAuthService } from './clarity-auth.service';
import { CachingService } from './cache/CachingService';
import { HttpHeaders, HttpClient, HttpHandler, } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClarityHttpService extends HttpClient {

  patchHeaders: HttpHeaders;
  patchOptions: any;

  constructor(handler: HttpHandler, protected router: Router, protected authService: ClarityAuthService,
    public cacheService: CachingService) {
    super(handler);
    this.patchHeaders = new HttpHeaders({ 'Content-Type': 'application/json-patch+json' });
    this.patchOptions = { headers: this.patchHeaders, withCredentials: true };
  }

  // request(method: string, url: string, options?: Object): Observable<any> {
  //   window.localStorage.setItem('prevUrl', window.location.href);
  //   if (!options) {
  //     options = { headers: new HttpHeaders() };
  //   }
  //   options = this.setHeaders(options);
  //   return super.request(method, url, options);
  // }

  // private catchErrors() {
  //   return (res: Response) => {
  //     window.localStorage.setItem('prevUrl', window.location.href);
  //     if (res.status === 401 || res.status === 403) {
  //       this.router.navigate(['/login']);
  //     }

  //     return throwError(res);
  //   };
  // }

  setHeaders(objectToSetHeadersTo: Request | any) {
    objectToSetHeadersTo.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
    objectToSetHeadersTo.headers.set('Current-culture', navigator.language);
    objectToSetHeadersTo.headers.set('Current-tz-offset', new Date().getTimezoneOffset());
  }
}
