import { Injectable, Inject } from '@angular/core';
import Dexie from 'dexie';
import { Redirection } from '../model/redirection';
import { CapcodRequest } from '../services/cache/CapcodRequest';

@Injectable({
    providedIn: 'root'
})
export class DexieInitService extends Dexie {
    requests: Dexie.Table<CapcodRequest, string>;
    redirections: Dexie.Table<Redirection, string>;

    constructor(@Inject('env') protected environment: any) {
        super(environment);
        this.version(1).stores({
            redirections: 'key',
            requests: 'key'
        });

        this.requests = this.table('requests');
        this.redirections = this.table('redirections');
    }
}
