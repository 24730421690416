import { Filters } from './api/Filters';
import { HttpParams } from '@angular/common/http';

export class CountRequest {
    public term: string;
    filters: Filters;

    public toHttpParams(): HttpParams {
        let params = new HttpParams();
        const keys = Object.keys(this);
        keys.forEach(k => {
            const val = this[k];
            if (val !== undefined && val !== null && k !== 'filters') {
                params = params.append(k, val.toString());
            }
        });

        if (this.filters && this.filters.customFilters) {
            this.filters.customFilters.forEach(filter => {
                params = params.append(filter.name, filter.value);
            });
        }

        return params;
    }
}
