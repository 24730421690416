import { ClarityListInterface } from '../interfaces/ClarityListInterface';
import { ClrDatagridStateInterface } from '@clr/angular';
import { GetAllRequest } from './GetAllRequest';
import { CrudServiceInterface } from '../interfaces/crudServiceInterface';
import { OnInit } from '@angular/core';
import { Filters } from './api/Filters';
import { Schema } from './api/Schema';
import { FieldEditorInformation } from './api/FieldEditorInformation';
import { FilterService } from './utils/filter.service';
import { TableStateService } from './utils/TableStateService';
import { LocalStorageService } from './utils/localstorage.service';

export class ListGeneric implements ClarityListInterface, OnInit {
  filterService;
  currentPageSize;
  currentPage = 1;
  objects: any[];
  loading = false;
  total: number;
  current: any;
  filters: Filters = new Filters();
  public schema: Schema;
  service: CrudServiceInterface;
  currentGetAllRequest: GetAllRequest;
  tablePropertieNames: FieldEditorInformation[];
  object: any;
  sortby: any;
  sortReverse: boolean;
  lastFilter: Filters = new Filters();
  public showAddModal = false;
  public term;
  initDone = false;
  tableStateService: LocalStorageService;
  pageSizes = [15, 20, 50, 100];

  constructor() {
    this.filterService = new FilterService();
    this.tableStateService = new TableStateService();
    this.setCurrentPage();
  }

  private setCurrentPage() {
    const lastFilter = this.tableStateService.getFiltersFromLocalStorage();
    this.currentPage = lastFilter.skip / lastFilter.length + 1;

    if (isNaN(this.currentPage)) {
      this.currentPage = 1;
    }

    const lastRequest = this.tableStateService.get();
    if (lastRequest) {
      this.currentPageSize = lastRequest.length;
    } else {
      this.currentPageSize = this.pageSizes[0];
    }
  }

  ngOnInit() {
    const filter = this.filterService.getFiltersFromLocalStorage();
    this.lastFilter = Object.assign({}, filter);
  }

  refresh(state: ClrDatagridStateInterface = null) {
    if (!this.service) { return; }

    if (!this.schema) {
      this.service.getSchema().subscribe(x => {
        this.schema = x;
        if (this.schema.classConfig) {
          this.sortReverse = this.schema.classConfig.defaultSortReverse;
          this.sortby = this.schema.classConfig.defaultSortProperty;
        }
        this.tablePropertieNames = this.schema.fieldEditorInfoAttributes.filter(fei => fei.tableDisplay && fei.displayName);
        this.loadData(state);
      });
    } else {
      this.loadData(state);
    }
  }

  private loadData(state: ClrDatagridStateInterface<any>) {
    this.generateGetAllRequest(state);
    this.doRequests();
    this.showAddModal = false;
  }

  doRequests() {
    if (this.service) {
      this.loading = true;
      this.tableStateService.set(this.currentGetAllRequest);
      this.service.count(this.currentGetAllRequest)
        .subscribe(c => {
          this.total = c;

          this.service.getAll(this.currentGetAllRequest)
            .subscribe(res => {
              this.objects = res;
              this.loading = false;
              this.afterLoad();
            });
        });
    }
  }

  afterLoad() {

  }

  reload(filter: Filters | any) {
    this.lastFilter = Object.assign({}, filter);
    this.refresh();
  }

  generateGetAllRequest(state: ClrDatagridStateInterface = null): void {
    if (state && state.sort) {
      this.sortby = state.sort.by;
      this.sortReverse = state.sort.reverse;
    }

    const request = new GetAllRequest();

    if (state && state.page) {
      request.length = state.page.size;
      request.skip = state.page.from;
      this.currentPageSize = request.length;
    } else {
      request.length = this.currentPageSize;
    }

    request.term = this.lastFilter.term;
    request.startAt = this.lastFilter.startAt;
    request.endAt = this.lastFilter.endAt;
    request.activeOnly = this.lastFilter.activeOnly;
    request.reverse = this.sortReverse;
    request.orderBy = this.sortby;
    request.filters = this.lastFilter;
    this.currentGetAllRequest = request;
  }

  hasSearchChanged(state): boolean {
    if (state && this.currentGetAllRequest) {
      return this.currentGetAllRequest.hasChanged(this.filters);
    }
    return false;
  }

  getService(): CrudServiceInterface {
    return this.service;
  }
}
