import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { ClarityHttpService } from './clarity-http.service';
import { Observable } from 'rxjs';
import { CachingService } from './cache/CachingService';
import { SnotifyService } from 'ng-snotify';

@Injectable({
    providedIn: 'root'
})
export class InfoService {
    public baseurl = `${this.environment.apiUrl}/info`;

    constructor(@Inject('env') protected environment, protected http: ClarityHttpService, private cache: CachingService,
        private snotifiyService: SnotifyService) {

    }

    version(): Observable<any> {
        return this.http.get(`${this.baseurl}`).pipe(
            map(x => {
                // const res = x.version;
                return x;
            }
            ));
    }

    public mustUpdate(): Observable<any> {
        return new Observable((obs) => {
            const versionKey = 'version';
            this.http.get(`${this.baseurl}`)
                .subscribe((res: any) => {
                    this.cache.get('info', versionKey)
                        .then(lastversion => {
                            if (lastversion.value.version !== res.version) {
                                this.updateVersion(versionKey, res).subscribe(x => {
                                    obs.next(x);
                                });
                            }
                        })
                        .catch(() => {
                            this.updateVersion(versionKey, res).subscribe(x => {
                                obs.next(x);
                            });
                        });
                });
        });
    }

    public checkForUpdates(): void {
        const versionKey = 'version';
        this.http.get(`${this.baseurl}`)
            .subscribe((res: any) => {
                this.cache.get('info', versionKey)
                    .then(lastversion => {
                        if (lastversion.value.version !== res.version) {
                            this.updateVersion(versionKey, res);
                        }
                    })
                    .catch(() => {
                        this.updateVersion(versionKey, res);
                        // this.cache.cache('info', versionKey, res);
                    });
            });
    }

    private updateVersion(versionKey: string, res: any): Observable<any> {
        return new Observable<any>((obs) => {
            this.snotifiyService.info('mise à jour du logiciel en cours. Veuillez patienter ...');
            this.cache.cacheObs('info', versionKey, res).subscribe(x => {
                obs.next(true);
            }, error => {
                obs.next(false);
                console.error('update version error');
                console.error(error);
            });

            setTimeout(() => {
                obs.next(true);
            }, 2500);
        });
    }
}
