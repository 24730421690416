import { Component, OnInit, Input, OnDestroy, Inject, OnChanges, Output, EventEmitter } from '@angular/core';
import { LoggerService, LocalStorageService, EventLog } from 'capcod-core';

@Component({
  selector: 'capcod-logger-client',
  templateUrl: './logger-client.component.html',
  styleUrls: ['./logger-client.component.scss']
})

export class LoggerClientComponent implements OnChanges, OnInit, OnDestroy {
  @Input() id: string;
  @Input() maxCalls = 0;
  @Input() maxHistory = 10;
  @Input() frequency = 3000;
  @Output() logChanged = new EventEmitter<EventLog[]>();

  storageKey = 'batchexecutions';
  poolInterval: any;
  lastUpdate: Date;
  key: string;
  logs: EventLog[];
  environment: any;
  calls = 0;
  syncInProgress;
  stopSync = false;

  constructor(@Inject('env') environment, private loggerService: LoggerService, private localStorageService: LocalStorageService) {
    this.environment = environment;
    this.key = `${this.environment.applicationName}_${this.storageKey}`;
  }

  ngOnInit() {
    this.launchInterval();
  }

  private launchInterval() {
    clearInterval(this.poolInterval);

    if (this.frequency > 0) {
      this.run();
      this.poolInterval = setInterval(() => {
        this.run();
      }, this.frequency);
    }
  }

  ngOnChanges(changes: any) {
    this.stopSync = false;
    if (this.key) {
      this.localStorageService.updateArray(this.key, this.id);
      this.launchInterval();
    }
  }

  private run() {
    this.getLogs(this.localStorageService.getArray(this.key));
    this.calls++;
    const doClear = this.maxCalls > 0 && this.maxCalls === this.calls || this.stopSync;
    if (doClear) {
      clearInterval(this.poolInterval);
    }
  }

  private getLogs(ids: string[] = null) {
    if (ids.length === 0) { return; }

    if (ids.length >= this.maxHistory) {
      ids.splice(0, ids.length - this.maxHistory);
    }

    this.lastUpdate = new Date();
    this.loggerService.getAll(ids).subscribe({
      next: (res: EventLog[]) => {
        this.logs = res;
        this.logChanged.emit(res);
        this.stopSync = res.every(x => x.done);
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.poolInterval);
  }
}
