import { CountRequest } from './CountRequest';
import { Filters } from './api/Filters';

export class GetAllRequest extends CountRequest {
    skip: number;
    length: number;
    orderBy: string;
    reverse: boolean;
    startAt: Date;
    endAt: Date;
    activeOnly: Boolean;

    constructor(length: number = 20, skip: number = 0, orderBy: string = 'id', reverse: boolean = false) {
        super();
        this.skip = skip;
        this.length = length;
        this.orderBy = orderBy;
        this.reverse = reverse;
    }

    public toCountRequest(): CountRequest {
        const request = new CountRequest();
        request.term = this.term;
        return request;
    }

    hasChanged(filters: Filters): boolean {
        return this.term !== filters.term || this.startAt !== filters.startAt || this.endAt !== filters.endAt
            || this.activeOnly !== filters.activeOnly;
    }
}
