import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { IGenericButtonStyle } from '../../classes/IGenericButtonStyle';
import { ClarityButtonStyle, ClarityButtonShape, ClarityButtonLevel } from '../../classes/ClarityButtonStyle';
import { ClrLoadingState } from '@clr/angular';
import { FieldEditorInformation, ValueChangedModel } from 'capcod-core';
@Component({
  selector: 'capcod-editor-modal',
  templateUrl: './editor-modal.component.html',
  styleUrls: ['./editor-modal.component.scss']
})
export class EditorModalComponent {
  openValue: boolean;
  @Output() openChange = new EventEmitter();
  @Input() allowRedirect = false;
  @Input() directAdd = true;
  @Input() feis: FieldEditorInformation[];
  @Input() excludeProperties = [];
  @Output() valueChanged = new EventEmitter<ValueChangedModel<any>>();
  @Input()
  get open() {
    return this.openValue;
  }
  set open(val) {
    this.openValue = val;
    if (val === true) {
      setTimeout(() => {
        const element = document.getElementById('input_0');
        if (element) {
          element.focus();
        }
      }, 1);
    }
    this.openChange.emit(this.openValue);
  }

  @Input() modalTitle = 'Ajout';
  @Input() service;
  @Input() canDelete = true;
  @Input() object;
  @Output() objectUpdated = new EventEmitter<any>();
  @Input() saveButtonStyle: IGenericButtonStyle = new ClarityButtonStyle(ClarityButtonShape.Standard, ClarityButtonLevel.Primary);
  addObjectSubject = new Subject<any>();
  sending: ClrLoadingState = ClrLoadingState.DEFAULT;

  handleValueChanged($event) {
    this.valueChanged.emit($event);
  }
}
