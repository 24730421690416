import { Component, OnInit, Input } from '@angular/core';
import { FieldEditorInformation, CrudServiceGeneric } from 'capcod-core';
import { TimeTrackedField } from './timeTrackedField';

@Component({
  selector: 'capcod-object-timetracked-view',
  templateUrl: './object-timetracked-view.component.html',
  styleUrls: ['./object-timetracked-view.component.scss']
})
export class ObjectTimetrackedViewComponent {
  @Input() object: any;
  @Input() title = 'Informations complémentaires';
  @Input() inCard = true;
  @Input() service: CrudServiceGeneric;

  public createdProperties = [new TimeTrackedField('createdAt', 'Date de création', true), new TimeTrackedField('createdBy', 'Créateur')];
  public updatedProperties = [new TimeTrackedField('updatedAt', 'Dernière modification', true), new TimeTrackedField('updatedBy', 'Modifié par')];
  public archivedProperties = [new TimeTrackedField('archivedAt', 'Date d\'archivage', true), new TimeTrackedField('archivedBy', 'Archivé par')];

  constructor() { }

}
