import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CustomSubjectMethod } from '../../../classes/custom-subject-method';
import { GanttCellModel } from '../../../classes/gantt/gantt-cell-model';
import { GanttRowModel } from '../../../classes/gantt/gantt-row-model';

@Component({
  selector: 'capcod-generic-gantt-row',
  templateUrl: './generic-gantt-row.component.html',
  styleUrls: ['./generic-gantt-row.component.scss']
})
export class GenericGanttRowComponent implements OnChanges {

  @Input() row: GanttRowModel;
  @Input() deadlineClient: Date;
  @Input() dates: Date[];
  @Input() currentDate: Date;
  @Input() withPercentage = false;
  @Input() fullSize = false;

  @Output() inputValueChanged = new EventEmitter<any>();
  @Output() deadlineValueChanged = new EventEmitter<any>();

  datesForGanttCell: GanttCellModel[];

  ngOnChanges(changes): void {
    if (!changes.dates) { return; }

    this.datesForGanttCell = this.dates.map(x =>
      new GanttCellModel({
        cellDate: new Date(x),
        currentDate: this.currentDate,
        planningStartDate: this.row.start,
        planningEndDate: this.row.end,
        progressEndDate: this.row.progressEnd,
        progressStartDate: this.row.progressStart,
        lastDayProgressPercent: this.row.lastDayProgressPercent,
        displayProgression: this.row.displayProgression
      })
    );
  }

  valueChanged(row) {
    this.inputValueChanged.next(row);
  }

  callMethod(row: GanttRowModel, customMethod: CustomSubjectMethod) {
    if (customMethod.subject) {
      customMethod.subject.next(row);
    } else {
      console.error('Subject is not defined in customMethod', customMethod);
    }
  }

  deadlineChange(event) {
    this.deadlineValueChanged.emit({ row: this.row, deadline: event });
  }
}
