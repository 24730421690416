import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventLog } from '../classes/api/event-log';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {
  constructor(private http: HttpClient) {

  }

  get(id: string): Observable<EventLog> {
    return this.http.get<EventLog>(`https://api.logger.capcod.cloud/api/log/${id}`);
  }

  getAll(ids: string[]): Observable<EventLog[]> {
    return this.http.post<EventLog[]>(`https://api.logger.capcod.cloud/api/log/list`, { ids: ids });
  }
}
