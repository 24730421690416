import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TrackerService } from '../../services/tracker.service';

@Injectable({
  providedIn: 'root'
})
export class DetailsGenericConfig {
  constructor(public location: Location, public trackerService: TrackerService, public router: Router) {
  }
}
