import { CrudServiceInterface } from '../interfaces/crudServiceInterface';
import { GetAllRequest } from './GetAllRequest';
import { CountRequest } from './CountRequest';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Inject } from '@angular/core';
import { ClarityHttpService } from '../services/clarity-http.service';
import { ValueChangedModel } from './ValueChangedModel';
import { addMinutes, isBefore, isDate } from 'date-fns';
import { Schema } from './api/Schema';
import { ValidationModel } from './api/ValidationModel';
import { CrudServiceGeneric } from './CrudServiceGeneric';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { CapcodRequest } from '../services/cache/CapcodRequest';
import { Environnement } from './config/environnement';

export class CrudServiceGenericCache extends CrudServiceGeneric implements CrudServiceInterface {

    public baseurl = `${this.environment.apiUrl}/${this.controllerName}`;

    constructor(@Inject('env') protected environment: Environnement, protected http: ClarityHttpService,
        @Inject('ctrl') protected controllerName: string = null) {
        super(environment, http, controllerName);
    }

    getAllFromTerm = (keyword: any): Observable<any[]> => {
        const gar = new GetAllRequest();
        gar.term = keyword;
        return this.getAll(gar)
            .pipe(
                // map(x => x.sort((a: LoadableObject, b: LoadableObject) => a.display.localeCompare(b.display)))
            );
    }

    getAll(request: GetAllRequest = null, action: string = ''): Observable<any> {
        if (!request) {
            request = new GetAllRequest();
        }

        let url = `${this.baseurl}`;
        if (action) {
            url += `/${action}`;
        }
        const cacheKey = this.getStorageKey(action, request.toHttpParams());
        return this.useCacheFallBack(cacheKey);
    }

    getStorageKey(url = '', params: HttpParams = null) {
        let res = url;
        if (params) {
            res += `?${params.toString()}`;
        }
        return res;
    }

    add(obj: any): Observable<ValidationModel<any>> {
        this.processDate(obj);
        this.breakCircularDependencies(obj);
        return this.http.post(`${this.baseurl}`, obj, { withCredentials: true }).pipe(map((x: ValidationModel<any>) => x));
    }

    breakCircularDependencies(obj: any): any {
        Object.keys(obj).forEach(el => {
            this.test(obj[el]);
        });
    }

    test(obj) {
        if (obj && obj.id) {
            const keys = Object.keys(obj);
            keys.forEach(k => {
                if (k !== 'id') {
                    delete obj[k];
                }
            });
        }
    }

    processDate(obj) {
        Object.keys(obj).forEach(key => {
            const val = obj[key];
            if (isDate(val)) {
            }
        });
    }

    calculate(obj: any): Observable<any> {
        return this.http.post(`${this.baseurl}/calculate`, obj, { withCredentials: true });
    }

    delete(id: any, reason: string = null): Observable<any> {
        const local = this;
        return new Observable(function (obs) {
            local.getSchema().subscribe(schema => {
                if (schema.interface === 'IArchiveObject') {
                    local.archive(id, reason).subscribe(res => {
                        obs.next(res);
                    });
                } else {
                    local.hardDelete(id).subscribe(res => {
                        obs.next(res);
                    });
                }
            });
        });
    }

    public archive(id: string, reason: string) {
        return this.http.post(`${this.baseurl}/${id}/archive`, { reason: reason });
    }

    unarchive(id: any): any {
        return this.http.post(`${this.baseurl}/${id}/unarchive`, {});
    }

    public hardDelete(id: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'q=0.8;application/json;q=0.9'
        });
        return this.http.delete(`${this.baseurl}/${id}`, { headers: headers, withCredentials: true });
    }

    count(request: CountRequest): Observable<any> {
        const cacheKey = this.getStorageKey(`count`, request.toHttpParams());
        return this.useCacheFallBack(cacheKey)
            .pipe(map(x => {
                return x;
            }));
    }

    get(key: any): Observable<any> {
        const cacheKey = this.getStorageKey(`${key}`);
        return this.useCacheFallBack(cacheKey)
            .pipe(map(x => {
                return x;
            }));
    }

    default(): Observable<any> {
        return this.http.get(`${this.baseurl}/default`, { withCredentials: true });
    }

    useCache(methodName: string, params: any = {}): Observable<any> {
        const local: CrudServiceGenericCache = this;
        return new Observable(function (obs) {
            local.http.cacheService.requests.get(local.getRequestKey(local.controllerName, methodName))
                .then((cache: CapcodRequest) => {
                    if (cache && isBefore(new Date(), addMinutes(cache.savedAt, 60))) {
                        obs.next(cache.value);
                    } else {
                        local.http.get(`${local.baseurl}/${methodName}`, { withCredentials: true, params: params }).pipe(
                            map(x => {
                                const jsonValue = x;
                                local.http.cacheService.cache(local.controllerName, methodName, jsonValue);
                                return jsonValue;
                            })
                        ).subscribe(res => {
                            obs.next(res);
                        });
                    }
                });
        });
    }

    getRequestKey(controllerName: string, request: string) {
        return this.http.cacheService.getKey(controllerName, request);
    }

    getCollection(key: any, collection: string): Observable<{ schema: Schema, result: any[] }> {
        return this.useCacheFallBack(`collection/${key}/${collection}`)
            .pipe(map(x => {
                return x;
            }));
    }


    public getSchema(): Observable<Schema> {
        return this.useCache('schema');
    }

    patch(id: any, patch: any): any {
        return this.http.patch(`${this.baseurl}/${id}`, patch, this.http.patchOptions);
    }

    prefillForm(object: ValueChangedModel<any>): void {
        console.warn('prefillForm is not implemented for service');
    }

    useCacheFallBack(methodName: string, params: any = {}, customkey: string = ''): Observable<any> {
        const local = this;
        return new Observable(function (obs) {
            local.http.cacheService.requests.get(local.getRequestKey(local.controllerName, methodName))
                .then((cache: CapcodRequest) => {
                    if (cache) {
                        obs.next(cache.value);
                    }

                    local.http.get(`${local.baseurl}/${methodName}`, { withCredentials: true, params: params }).subscribe(
                        success => {
                            local.http.cacheService.cache(local.controllerName, methodName, success);
                            obs.next(success);
                            obs.complete();
                        },
                        () => {
                            if (cache) {
                                obs.next(cache.value);
                            }
                            obs.complete();
                        });
                });
        });
    }
}
