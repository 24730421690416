import { Injectable } from '@angular/core';
import { Filters } from '../api/Filters';

@Injectable({
  providedIn: 'root'
})
export abstract class LocalStorageService {
  protected abstract localStorageSuffix: string;
  protected abstract localStorageType: string;

  getArray(key: string): any[] {
    const localStorageValue = localStorage.getItem(key);
    const parsedValue = JSON.parse(localStorageValue);
    if (parsedValue) { return parsedValue; }

    return [];
  }

  clean(key: string) {
    localStorage.removeItem(key);
  }

  updateArray(key: string, value: string, maxLength: number = 5) {
    const base = this.getArray(key);

    if (base.indexOf(value) === -1 && value) {
      base.push(value);
    }

    if (base.length > maxLength) {
      base.splice(0, 1);
    }

    localStorage.setItem(key, JSON.stringify(base));
  }

  set(filter: any): void {
    localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(filter));
  }

  get(): any {
    return  JSON.parse(localStorage.getItem(this.getLocalStorageKey()));
  }

  getLocalStorageKey(): string {
    return `${this.localStorageSuffix}.${location.pathname}.${this.localStorageType}`;
  }

  removeAllStockedFilters(): void {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(this.localStorageSuffix)) {
        localStorage.removeItem(key);
      }
    });
  }

  getFiltersFromLocalStorage(): any {
    let filter = new Filters();
    const valueFromLocalStorage = localStorage.getItem(this.getLocalStorageKey());
    if (valueFromLocalStorage !== undefined && valueFromLocalStorage !== null) {
      filter = JSON.parse(valueFromLocalStorage);
    }
    return filter;
  }
}
