import { CustomFilter } from './custom-filter';

export class Filters {
    term: string;
    activeOnly: Boolean = true;
    startAt: Date;
    endAt: Date;
    customFilters: CustomFilter[];
    customPayLoad: any;
}
