import { CrudServiceInterface } from '../interfaces/crudServiceInterface';
import { GetAllRequest } from './GetAllRequest';
import { CountRequest } from './CountRequest';
import { Observable } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { Inject } from '@angular/core';
import { ClarityHttpService } from '../services/clarity-http.service';
import { HttpHeaders } from '@angular/common/http';
import { ValueChangedModel } from './ValueChangedModel';
import { addMinutes, isBefore, isDate } from 'date-fns';
import { Schema } from './api/Schema';
import { ValidationModel } from './api/ValidationModel';
import { saveAs } from 'file-saver';
import { Environnement } from './config/environnement';
import { CapcodRequest } from '../services/cache/CapcodRequest';

export class CrudServiceGeneric implements CrudServiceInterface {
  public baseurl = `${this.environment.apiUrl}/${this.controllerName}`;

  constructor(@Inject('env') protected environment: Environnement, protected http: ClarityHttpService,
    @Inject('ctrl') protected controllerName: string = null) {
    if (!this.environment.apiUrl) {
      throw new Error('I need and \'apiUrl\' key in the environnement');
    }
  }

  getAllFromTerm = (keyword: any): Observable<any[]> => {
    const gar = new GetAllRequest();
    gar.term = keyword;
    // gar.orderBy = 'display';

    return this.getAll(gar)
      .pipe(
        debounceTime(1000),
        map(x => {
          return x;
        }));
  }

  getCollection(key: any, collection: string): Observable<{ schema: Schema, result: any[] }> {
    return this.useCacheFallBack(`collection/${key}/${collection}`)
      .pipe(map(x => {
        return x;
      }));
  }

  public getAllFromTermCache = (keyword: any): Observable<any[]> => {
    const gar = new GetAllRequest();
    gar.term = keyword;
    return this.useCache('', { term: keyword })
      .pipe(map(x => {
        return x;
        // return x.sort((a: LoadableObject, b: LoadableObject) => a.display.localeCompare(b.display));
      }));
  }

  getAll(request: GetAllRequest = null, action: string = null): Observable<any[]> {
    let paylod = null;
    if (request && request.filters) {
      paylod = request.filters.customPayLoad;
    }
    let url = `${this.baseurl}`;
    if (action) {
      url += `/${action}`;
    }

    if (paylod && Object.keys(paylod).length > 0) {
      const postRequest = {
        skip: request.skip,
        length: request.length,
        orderBy: request.orderBy,
        sortMode: request.reverse ? 'DESC' : 'ASC',
        parameters: paylod
      };
      return this.http.post(url + '/getall', postRequest, { withCredentials: true })
        .pipe(
          debounceTime(1000),
          map((x: any) => x));
    } else {
      if (!request) {
        request = new GetAllRequest();
      }

      return this.http.get(url, { params: request.toHttpParams(), withCredentials: true })
        .pipe(
          debounceTime(1000),
          map((x: any) => x));
    }
  }

  add(obj: any): Observable<ValidationModel<any>> {
    this.processDate(obj);
    this.breakCircularDependencies(obj);
    return this.http.post(`${this.baseurl}`, obj, { withCredentials: true }).pipe(map((x: ValidationModel<any>) => x));
  }

  breakCircularDependencies(obj: any): any {
    Object.keys(obj).forEach(el => {
      this.test(obj[el]);
    });
  }

  test(obj) {
    if (obj && obj.id) {
      const keys = Object.keys(obj);
      keys.forEach(k => {
        if (['id', 'display'].indexOf(k) === -1) {
          delete obj[k];
        }
      });
    }
  }

  processDate(obj) {
    Object.keys(obj).forEach(key => {
      const val = obj[key];
      if (isDate(val)) {
        const isoDate = new Date(val);
      }
    });
  }

  calculate(obj: any): Observable<any> {
    return this.http.post(`${this.baseurl}/calculate`, obj, { withCredentials: true });
  }

  delete(id: any, reason: string = null): Observable<any> {
    const local = this;
    return new Observable(function (obs) {
      local.getSchema().subscribe(schema => {
        if (schema.interface === 'IArchiveObject') {
          local.archive(id, reason).subscribe(res => {
            obs.next(res);
          });
        } else {
          local.hardDelete(id).subscribe(res => {
            obs.next(res);
          });
        }
      });
    });
  }

  public archive(id: string, reason: string) {
    return this.http.post(`${this.baseurl}/${id}/archive`, { reason: reason }).pipe(map(x => {
      return x;
    }));
  }

  unarchive(id: any): any {
    return this.http.post(`${this.baseurl}/${id}/unarchive`, {}).pipe(map(x => {
      return x;
    }));
  }

  public hardDelete(id: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'q=0.8;application/json;q=0.9'
    });
    return this.http.delete(`${this.baseurl}/${id}`, { headers: headers, withCredentials: true });
  }

  count(request: CountRequest): Observable<any> {
    return this.http.get(`${this.baseurl}/count`, { params: request.toHttpParams(), withCredentials: true });
  }

  get(key: any): Observable<any> {
    return this.http.get(`${this.baseurl}/${key}`, { withCredentials: true });
  }

  default(): Observable<any> {
    return this.http.get(`${this.baseurl}/default`, { withCredentials: true });
  }

  useCache(methodName: string, params: any = {}): Observable<any> {
    const local: CrudServiceGeneric = this;
    return new Observable(function (obs) {
      local.http.cacheService.requests.get(local.getRequestKey(local.controllerName, methodName))
        .then((cache: CapcodRequest) => {
          if (cache && isBefore(new Date(), addMinutes(cache.savedAt, 60))) {
            obs.next(cache.value);
          } else {
            local.http.get(`${local.baseurl}/${methodName}`, { withCredentials: true, params: params }).pipe(
              map(x => {
                const jsonValue = x;
                local.http.cacheService.cache(local.controllerName, methodName, jsonValue);
                return jsonValue;
              })
            ).subscribe(res => {
              obs.next(res);
            });
          }
        });
    });
  }

  useForcedCache(methodName: string, renew: boolean = false, params: any = {}): Observable<any> {
    const local = this;
    return new Observable(function (obs) {
      local.http.cacheService.requests.get(local.getRequestKey(local.controllerName, methodName))
        .then((cache: CapcodRequest) => {
          if (cache && !renew) {
            obs.next(cache.value);
          } else {
            local.http.get(`${local.baseurl}/${methodName}`, { withCredentials: true, params: params }).pipe(
              map(x => {
                const jsonValue = x;
                local.http.cacheService.cache(local.controllerName, methodName, jsonValue);
                return jsonValue;
              })
            ).subscribe(res => {
              obs.next(res);
            });
          }
        });
    });
  }

  getRequestKey(controllerName: string, request: string) {
    return this.http.cacheService.getKey(controllerName, request);
  }


  public getSchema(): Observable<Schema> {
    return this.useCache('schema');
  }

  patch(id: any, patch: any): Observable<any> {
    return this.http.patch(`${this.baseurl}/${id}`, patch, this.http.patchOptions);
  }

  prefillForm(object: ValueChangedModel<any>): void {
    console.warn('prefillForm is not implemented for service');
  }

  useCacheFallBack(methodName: string, params: any = {}, customkey: string = ''): Observable<any> {
    const local = this;
    return new Observable(function (obs) {
      local.http.get(`${local.baseurl}/${methodName}`, { withCredentials: true, params: params }).pipe(
        map(x => {
          const jsonValue = x;
          local.http.cacheService.cache(local.controllerName, methodName + customkey, jsonValue);
          return jsonValue;
        })
      ).subscribe(
        success => {
          obs.next(success);
        },
        error => {
          local.http.cacheService.requests.get(local.getRequestKey(local.controllerName, methodName + customkey))
            .then((cache: CapcodRequest) => {
              obs.next(cache.value);
            });
        });
    });
  }


  public download(id: string, fileName: string): Observable<any> {
    return new Observable((obs) => {
      const path = `${this.baseurl}/${id}/download/${fileName}`;
      this.http.get(path, { withCredentials: true, responseType: 'blob' }).subscribe(x => {
        const blob = new Blob([x], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, fileName);
        obs.next();
      });
    });
  }

  public downloadFile(endpoint: string, fileName: string, extension: string): Observable<any> {
    return new Observable((obs) => {
      endpoint = `${this.baseurl}/download/${endpoint}`;
      const header = new HttpHeaders({ 'Content-Type': this.getMimeFromExtension(extension) });
      return this.http.get(endpoint, { headers: header, withCredentials: true, responseType: 'blob' }).subscribe(response => {
        saveAs(response, `${fileName}.${extension}`);
        obs.next();
      });
    });
  }

  private getBlobOptionsHttp(header: HttpHeaders) {
    return {
      headers: header,
      withCredentials: true,
      responseType: 'blob'
    };
  }

  private getMimeFromExtension(extension: string) {
    const imageExtensions = ['png', 'jpeg', 'jpg', 'gif', 'svg'];
    if (imageExtensions.indexOf(extension) > -1) {
      switch (extension) {
        case 'jpg': return 'image/jpeg';
        case 'svg': return 'image/svg+xml';
        default: return `image/${extension}`;
      }
    } else {
      switch (extension) {
        case 'txt': return 'text/plain';
        case 'csv': return 'text/csv';
        case 'pdf': return 'application/pdf';
        case 'doc': return 'application/vnd.ms-word';
        case 'docx': return 'application/vnd.ms-word';
        case 'xls': return 'application/vnd.ms-excel';
        case 'zip': return 'application/zip';
        default: return '';
      }
    }
  }

  public uploadFiles(objectId: string, files: File[], propertyName: string): Observable<any> {
    const formData: FormData = new FormData();
    files.forEach(file => {
      formData.append('file', file, file.name);
    });
    return this.sendUploadRequest(objectId, formData, propertyName);
  }

  private sendUploadRequest(objectId: string, formData: FormData, propertyName: string): Observable<any> {
    const request = `${this.baseurl}/${objectId}/upload/${propertyName}`;
    return this.http.post(request, formData, { withCredentials: true, });
  }

  public uploadFile(objectId: string, file: File, propertyName: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.sendUploadRequest(objectId, formData, propertyName);
  }

  public getFiles(id: string, propertyName: string): Observable<any> {
    return this.http.get(`${this.baseurl}/${id}/files/${propertyName}`, { withCredentials: true });
  }

  public deleteFile(id: string, propertyName: string, fileName: string): Observable<any> {
    const request = `${this.baseurl}/${id}/detach/${propertyName}/${fileName}`;
    return this.http.delete(request, { withCredentials: true });
  }

}
