import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudServiceGeneric } from '../classes/CrudServiceGeneric';
import { ClarityHttpService } from './clarity-http.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class GenericUserService extends CrudServiceGeneric {
  constructor(@Inject('env') protected environment, protected http: ClarityHttpService, protected accountService: AccountService) {
    super(environment, http, 'user');
  }

  public connectAs(id: any, redirectTo: string): Observable<any> {
    return new Observable((obs) => {
      this.http.get(`${this.baseurl}/connect/${id}`, { withCredentials: true }).subscribe((data: any) => {
        if (data.token) {
          localStorage.setItem('token', data.token);
          this.accountService.setRoles().subscribe({
            next: () => {
              if (redirectTo) {
                window.location.href = redirectTo;
              }
              obs.next();
            }
          });
        }
      }
      );
    });
  }
}
