import { Component, OnInit, Input } from '@angular/core';
import { ServiceProvider, FieldEditorInformation, CrudServiceGeneric } from 'capcod-core';
import { Observable, concat, of, Subject } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'capcod-autocomplete2',
  templateUrl: './autocomplete2.component.html',
  styleUrls: ['./autocomplete2.component.scss']
})
export class AutoComplete2Component implements OnInit {

  @Input() lock = false;
  @Input() object: any;
  @Input() fei: FieldEditorInformation;
  @Input() observableSource: Observable<any>;
  @Input() service: CrudServiceGeneric;
  @Input() methodName = 'GetAllFromTerm';
  inputs = new Subject<string>();
  loading = false;

  constructor(private serviceProvider: ServiceProvider) { }

  ngOnInit(): void {
    if (this.fei && !this.fei.observableSource) {
      const feiService = this.serviceProvider.getInstance(this.fei.type);
      this.fei.observableSource = feiService.getAllFromTerm;
    } else {
      this.loadData();
    }
  }

  private loadData() {
    this.observableSource = concat(
      of([]), // default items
      this.inputs.pipe(
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap(term => this.service[this.methodName](term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.loading = false)
        ))
      )
    );
  }

}
