import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { CrudServiceInterface, FieldEditorInformation, ServiceProvider, ValueChangedModel } from 'capcod-core';
import { Subject } from 'rxjs';
import { CustomMethod } from '../../classes/custom-method';
import { ViewMode } from '../../enums/ViewMode';

@Component({
  selector: 'capcod-object-view',
  templateUrl: './object-view.component.html',
  styleUrls: ['./object-view.component.scss']
})
export class ObjectViewComponent implements OnInit {
  @Input() key: string;
  @Input() path: string;
  @Input() service: CrudServiceInterface;
  @Input() title = 'objects';
  @Input() modalTitle = 'missing modalTitle parameter';
  @Input() inCard = true;
  @Input() lock = false;
  @Input() lockEdit = false;
  @Input() lockDelete = false;
  @Input() canDelete = true;
  @Input() mode = ViewMode.Card;
  @Input() allowViewSwitch = true;
  @Input() excludeProperties = [];
  @Input() reloadSubject = new Subject();
  @Input() addButtonLabel = 'Ajouter';
  @Input() allowAdd = true;
  @Input() defaultAddObject = {};
  @Input() customMethods: CustomMethod[];
  @Input() feis: FieldEditorInformation[];
  @Output() elementDeleted = new EventEmitter();
  @Output() valueChanged = new EventEmitter<ValueChangedModel<any>>();
  @Output() elementAdded = new EventEmitter<any>();
  @Output() elementUpdated = new EventEmitter<any>();
  @Input() redirectAfterDelete = true;
  @Input() addModalTitle = 'missing addModalTitle parameter';

  collectionService: CrudServiceInterface;
  keys: string[];
  values = [];
  schema: any;
  isAddModalVisible = false;

  objectViewId = UUID.UUID();

  constructor(private serviceProvider: ServiceProvider) { }

  onNewObjectAdded(evt) {
    this.isAddModalVisible = false;
    this.loadCollection();
    this.elementAdded.emit(evt);
    if (this.reloadSubject) {
      this.reloadSubject.next();
    }
  }

  objectUpdated($event) {
    this.elementUpdated.next($event);
  }

  loadCollection() {
    this.service.getCollection(this.key, this.path).subscribe(x => {
      const data = x.result;
      this.schema = x.schema;
      if (!this.feis) {
        this.feis = this.schema.fieldEditorInfoAttributes;
      }
      this.collectionService = this.serviceProvider.getInstance(this.schema.typeName);
      if (Array.isArray(data)) {
        this.values = data;
      } else {
        this.values[0] = data;
      }

      if (this.values[0]) {
        this.keys = Object.keys(this.values[0]);
      }
    });
  }

  onValueChanged($event) {
    this.valueChanged.emit($event);
  }

  ngOnInit() {
    this.loadCollection();
    this.checkViewMode();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkViewMode();
  }

  private checkViewMode() {
    if (window.innerWidth < 738) {
      this.mode = 0;
    } else {
      this.mode = 1;
    }
  }
}
