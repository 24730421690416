import { ValidationModel, ErrorType } from '../classes/api/ValidationModel';
import { StringUtils } from '../classes/utils/StringUtils';

export class ErrorParser {
    static extractErrorMessages(model: ValidationModel<any>): string[] {
        const res = [];
        model.errors.forEach(error => {
            let fieldname = error.propName;
            if (error.attribute) {
                fieldname = error.attribute.displayName == null ? error.attribute.name : error.attribute.displayName;
            }

            let errMessage = `Le champ ${StringUtils.uncapitalize(fieldname)}`;

            switch (error.type) {
                case ErrorType.Required:
                    errMessage += ` doit contenir une valeur`;
                    break;
                    case ErrorType.NotUnique:
                        errMessage += ` est déjà utilisé`;
                        break;
            }

            res.push(errMessage);
        });
        return res;
    }
}
