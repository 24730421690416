import { NgModule, ModuleWithProviders } from '@angular/core';
import { ObjectEditorComponent } from './object-editor/object-editor.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ObjectViewComponent } from './object-view/object-view.component';
import { ClarityModule, ClrFormsModule } from '@clr/angular';
import { LoginComponent } from './login/login.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { TableComponent } from './table/table.component';
import { CellTemplaterComponent } from './cell-templater/cell-templater.component';
import { SnotifyModule } from 'ng-snotify';
import { FilterComponent } from './filter/filter.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { InputGeneratorComponent } from './input-generator/input-generator.component';
import { EditorModalComponent } from './editor-modal/editor-modal.component';
import { SelectorComponent } from './selector/selector.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PasswordUpdateComponent } from './password-update/password-update.component';
import { ValueTemplaterComponent } from './value-templater/value-templater.component';
import { RouterModule } from '@angular/router';
import { FileDropModule } from 'ngx-file-drop';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { MostVisitedComponent } from './most-visited/most-visited.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerClientComponent } from './logger-client/logger-client.component';
import { WebcamManagerComponent } from './webcam-manager/webcam-manager.component';
import { WebcamModule } from 'ngx-webcam';
import { CapcodCoreModule, ServiceProvider, ClarityHttpInterceptor } from 'capcod-core';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { TimetrackedRowGeneratorComponent } from './timetracked-row-generator/timetracked-row-generator.component';
import { ObjectTimetrackedViewComponent } from './object-timetracked-view/object-timetracked-view.component';
import { FollowMailStatusComponent } from './follow-mail-status/follow-mail-status.component';
import { TestAlertComponent } from './test-alert/test-alert.component';
import { EditInPlaceComponent } from './edit-in-place/edit-in-place.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutoComplete2Component } from './autocomplete2/autocomplete2.component';
import { GenericGanttCellComponent } from './gantt/generic-gantt-cell/generic-gantt-cell.component';
import { GenericGanttComponent } from './gantt/generic-gantt/generic-gantt.component';
import { GenericGanttDatesComponent } from './gantt/generic-gantt-dates/generic-gantt-dates.component';
import { GenericGanttRowComponent } from './gantt/generic-gantt-row/generic-gantt-row.component';
import { WeekPickerComponent } from './gantt/week-picker/week-picker.component';


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NguiAutoCompleteModule,
    ClrFormsModule,
    ClarityModule,
    RouterModule.forChild([]),
    SnotifyModule,
    CKEditorModule,
    FileDropModule,
    CapcodCoreModule,
    WebcamModule,
    NgSelectModule
  ],
  declarations: [
    ValueTemplaterComponent,
    EditInPlaceComponent,
    TestAlertComponent,
    PasswordUpdateComponent,
    ObjectEditorComponent,
    MostVisitedComponent,
    CellTemplaterComponent,
    AutocompleteComponent,
    ConfirmComponent,
    InputGeneratorComponent,
    SelectorComponent,
    ObjectViewComponent,
    TableComponent,
    LoggerClientComponent,
    FilterComponent,
    EditorModalComponent,
    LoginComponent,
    FileManagerComponent,
    WebcamManagerComponent,
    ObjectTimetrackedViewComponent,
    TimetrackedRowGeneratorComponent,
    FollowMailStatusComponent,
    AutoComplete2Component,
    GenericGanttCellComponent,
    GenericGanttComponent,
    GenericGanttDatesComponent,
    GenericGanttRowComponent,
    WeekPickerComponent
  ],
  exports: [
    PasswordUpdateComponent,
    MostVisitedComponent,
    CellTemplaterComponent,
    ValueTemplaterComponent,
    LoggerClientComponent,
    TestAlertComponent,
    EditInPlaceComponent,
    SelectorComponent,
    ObjectViewComponent,
    ConfirmComponent,
    InputGeneratorComponent,
    EditorModalComponent,
    FilterComponent,
    AutocompleteComponent,
    AutoComplete2Component,
    TableComponent,
    ObjectEditorComponent,
    FileManagerComponent,
    ObjectTimetrackedViewComponent,
    FollowMailStatusComponent,
    GenericGanttCellComponent,
    GenericGanttComponent,
    GenericGanttDatesComponent,
    GenericGanttRowComponent,
    WeekPickerComponent
  ],
  providers: [
    ServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClarityHttpInterceptor,
      multi: true
    }
  ],
})
export class CapcodClarityModule {
  constructor() {

  }

  public static forRoot(environment: any): ModuleWithProviders {
    return {
      ngModule: CapcodClarityModule,
      providers: [
        {
          provide: 'env',
          useValue: environment
        }
      ]
    };
  }

  public static forChild(environment: any): ModuleWithProviders {
    return {
      ngModule: CapcodClarityModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment
        }
      ]
    };
  }
}
