import { Component, OnInit, Input } from '@angular/core';
import { TimeTrackedField } from '../object-timetracked-view/timeTrackedField';

@Component({
  selector: 'capcod-timetracked-row-generator',
  templateUrl: './timetracked-row-generator.component.html',
  styleUrls: ['./timetracked-row-generator.component.css']
})
export class TimetrackedRowGeneratorComponent implements OnInit {
  @Input() timeTrackedFields: TimeTrackedField[];
  @Input() object: any;

  constructor() { }

  ngOnInit() {
  }

}
