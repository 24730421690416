export class Buildable {
    constructor(partial: Partial<Buildable>) {
        this.build(partial);
    }

    build(partial: Partial<Buildable>) {
        Object.keys(partial).forEach(key => {
            this[key] = partial[key];
        });
    }
}
