import { ClarityHttpService } from './clarity-http.service';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudServiceGenericCache } from '../classes/CrudServiceGenericCache';
import { DynamicCrudServiceInterface } from './DynamicCrudServiceInterface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DynamicCrudServiceGenericCache extends CrudServiceGenericCache implements DynamicCrudServiceInterface {
    collection: string;
    key: string;

    constructor(@Inject('env') protected environment, protected http: ClarityHttpService) {
        super(environment, http, null);
    }

    setControllerName(name: string) {
        this.baseurl = `${this.environment.apiUrl}/${name}`;
        this.controllerName = name;
    }

    setupCollectionGetter(key: string, colName: string) {
        this.key = key;
        this.collection = colName;
    }

    getCollectionForTerm = (keyword: any): Observable<any[]> => {
        if (!this.key || !this.collection) {
            throw new Error('You must set key and collection before calling this method (with setupCollectionGetter(key, collection))');
        }

        const cacheKey = this.getStorageKey(`collection/${this.key}/${this.collection}`);
        return this.useCacheFallBack(cacheKey).pipe(map(x => x.result));
    }
}
