import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'capcod-test-alert',
  templateUrl: './test-alert.component.html',
  styleUrls: ['./test-alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TestAlertComponent implements OnInit {
  isTestEnv = false;


  ngOnInit(): void {
    this.isTestEnv = window.location.href.indexOf('test.capcod.cloud') > -1;
  }
}
