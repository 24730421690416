import { Buildable } from 'capcod-core';

export class GanttRowSignpostModel extends Buildable {
    displayStartDate = true;
    displayEndDate = true;
    signpostDisplays: string[];

    constructor(partial: Partial<GanttRowSignpostModel>) {
        super(partial);
        super.build(partial);
    }
}
