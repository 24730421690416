import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { ClarityButtonStyle } from '../../classes/ClarityButtonStyle';
import { FieldEditorInformation, ListFormatters, ServiceProvider, StringUtils, LoadableObject } from 'capcod-core';

@Component({
  selector: 'capcod-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteComponent implements OnInit {
  @Input() lock = false;
  @Input() defaultValue;
  @Input() object: any;
  @Input() index = 0;
  @Input() fei: FieldEditorInformation;
  @Input() directAdd = false;
  @Input() acceptUserInput = false;
  @Input() observableSource = false;
  @Input() saveButtonStyle = new ClarityButtonStyle();
  @Input() clearAfterSelect = false;
  @Output() valueChanged = new EventEmitter<any>();
  formatters = new ListFormatters();
  mtmService: any;
  service: any;
  hasManyToMany = false;
  prop: any;

  constructor(private serviceProvider: ServiceProvider) {

  }

  ngOnInit() {
    if (this.fei && this.fei.lookupProperty) {
      this.mtmService = this.serviceProvider.getInstance(this.fei.lookupProperty);
      this.mtmService.getSchema().subscribe(schema => {
        this.prop = schema.fieldEditorInfoAttributes.find(x => x.name === StringUtils.uncapitalize(this.fei.lookupCollection));
        if (this.prop && this.prop.manyToManyPath) {
          this.service = this.serviceProvider.getInstance(this.prop.type);
          this.hasManyToMany = true;
        }
      });
    } else if (this.fei && !this.fei.observableSource) {
      const feiService = this.serviceProvider.getInstance(this.fei.type);
      this.fei.observableSource = feiService.getAllFromTerm;
    }

    if (this.defaultValue) {
      if (!this.object) {
        this.object = {};
      }

      if (!this.fei) {
        this.fei = new FieldEditorInformation();
        this.fei.name = 'fakefei';
      }
      if (this.defaultValue) {
        this.object[this.fei.name] = this.defaultValue;
      }
    }
  }

  dislayAddPopupFor() {
    if (this.fei.type) {
      this.fei.service = this.serviceProvider.getInstance(this.fei.type);
    }
    this.fei.showModal = true;
  }

  checkValue(event) {
    if (!event) {
      if (this.fei) {
        this.object[this.fei.name] = '';
      }
    } else {
      if (this.hasManyToMany) {
        this.dealWithMtmtAffectation();
      }
    }

    const result = this.fei ? this.fei : event;

    this.valueChanged.emit(result);
    this.clearField();
  }

  clearField() {
    setTimeout(() => {
      if (this.clearAfterSelect) {
        if (this.object && this.fei) {
          this.object[this.fei.name] = null;
        }
        this.defaultValue = '';
      }
    }, 100);
  }

  dealWithMtmtAffectation() {
    const propName = StringUtils.uncapitalize(this.fei.type);
    const innerVal = this.object[this.fei.name][propName];
    this.object[this.fei.name] = innerVal;
    this.valueChanged.emit(this.fei);
  }

  handleObjectAdded(event: LoadableObject, fei: FieldEditorInformation) {
    this.object[fei.name] = event;

    if (this.hasManyToMany) {
      this.service = this.serviceProvider.getInstance(this.prop.type);
      const obj = {};
      obj[fei.lookupProperty] = this.object[StringUtils.uncapitalize(fei.lookupProperty)];
      obj[this.prop.manyToManyPath] = event;
      this.service.add(obj).subscribe(res => {
      });
      this.dealWithMtmtAffectation();
    }

    fei.showModal = false;
    this.valueChanged.emit(this.fei);
  }

  emptyField() {
    if (this.object && this.fei) {
      this.object[this.fei.name] = null;
    }
    if (this.observableSource && !this.fei) {
      this.defaultValue = null;
    }

    if (this.fei) {
      this.valueChanged.emit(this.fei);
    } else {
      this.valueChanged.emit(null);
    }
    this.clearField();
  }
}
