import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from './roles';
import { AccountService } from '../../services/account.service';
import { GuardHelper } from '../../security/guard-helper';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad, CanActivate {
  authorized = false;
  constructor(private userService: AccountService) { }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAdmin();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.isAdmin();
  }

  isAdmin(): boolean {
    return GuardHelper.hasRole([Role.Admin]);
  }
}
