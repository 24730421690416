import { Injectable } from '@angular/core';
import { Filters } from '../api/Filters';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService extends LocalStorageService {
  protected localStorageType = 'filters';
  protected localStorageSuffix = 'temp';
}
