import { UUID } from 'angular2-uuid';

export class Redirection {
  url: string;
  logdate: Date;
  key: string;
  content: string;
  count: number;
  description: string;
  constructor(url: string, text: string) {
    this.url = url;
    this.logdate = new Date();
    this.content = text;
    this.key = UUID.UUID();
  }

  toString() {
    return `${this.content} - ${this.url}`;
  }
}
