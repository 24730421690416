import { Component, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClrLoadingState } from '@clr/angular';
import * as ClassicEditor from 'capcod-ckeditor';
import { ChangeEvent } from 'capcod-ckeditor';
import {
  ClarityHttpService, CrudServiceGeneric,
  DynamicCrudServiceGeneric,
  DynamicCrudServiceGenericCache, DynamicCrudServiceInterface, FeiService, FieldEditorInformation, ListFormatters, LoadableObject,
  Schema, ServiceProvider, StringUtils,
  ValueChangedModel
} from 'capcod-core';
import { format, getHours, getMinutes, isDate, setHours, setMinutes } from 'date-fns';
import { Subject } from 'rxjs';
import { ClarityButtonStyle } from '../../classes/ClarityButtonStyle';
import { CSharpTypes } from '../../classes/CSharpTypes';
import { IGenericButtonStyle } from '../../classes/IGenericButtonStyle';
import { EditMode } from '../../enums/EditMode';
import { CityService } from '../../services/city.service';

@Component({
  selector: 'capcod-input-generator',
  templateUrl: './input-generator.component.html',
  styleUrls: ['./input-generator.component.scss']
})
export class InputGeneratorComponent implements OnInit, OnChanges {
  nativeDate: string;
  @Input() fei: FieldEditorInformation;
  @Input() baseService: CrudServiceGeneric;
  @Input() directAdd = false;
  @Input() schema: Schema;
  @Input() updateSubject: Subject<any> = new Subject<any>();
  @Input() forcedFei = false;
  @Input() index = 0;
  @Input() hideLabel;
  objectValue: any;
  @Output() objectChange = new EventEmitter();
  @Input()
  get open() {
    return this.objectValue;
  }
  set open(val) {
    this.objectValue = val;
    this.objectChange.emit(this.objectValue);
  }
  @Input() object: any;
  @Output() fieldChanged = new EventEmitter<any>();

  // old
  @Input() lock = false;
  @Input() inCard = true;
  @Input() title = 'Edition';
  @Input() saveTitle = 'Enregister';
  @Input() successMessage = 'L\'object a bien été mis à jour.';
  @Input() subPath = '';
  @Input() saveButtonStyle: IGenericButtonStyle = new ClarityButtonStyle();
  @Input() excludeProperties = [];
  @Input() canDelete = true;
  @Output() objectAdded = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<ValueChangedModel<any>>();
  @Input() feis: FieldEditorInformation[];
  public Editor = ClassicEditor;
  inputs: any[] = [];
  formatters = new ListFormatters();
  clone;
  timeValue: any;
  ready = false;
  completeValue = '';
  textEditTypes = ['String', 'Decimal', 'Single', 'Double'];
  injectedValue: any;
  defaultValue: any;
  sending: ClrLoadingState = ClrLoadingState.DEFAULT;
  opendelete = false;
  mode = EditMode.Add;
  snoStyle = 'material';
  fcService: CrudServiceGeneric;
  displayValue: string;

  constructor(@Inject('env') protected environment, protected http: ClarityHttpService, private serviceProvider: ServiceProvider,
    public feiService: FeiService, private cityService: CityService) {
    if (environment.snotifyStyle) {
      this.snoStyle = environment.snotifyStyle;
    }


  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.useNativeDatePicker();
  }

  useNativeDatePicker() {
    if (this.fei.type === CSharpTypes.DateTime) {
      const date = this.object[this.fei.name];
      if (date) {
        this.nativeDate = format(date, 'yyyy-MM-dd');
      }
    }
  }

  onDateChange($event) {
    this.object[this.fei.name] = new Date($event.target.value);
  }

  ngOnInit(): void {
    this.updateDisplay();
    this.updateSubject.subscribe(x => {
      this.prepareInputField();
      this.updateDisplay();
    });


  }


  private updateDisplay() {
    if (this.fei.possibleValues) {
      const matchingEnum = this.fei.possibleValues.find(x => x.value === this.object[this.fei.name]);
      if (matchingEnum) {
        this.displayValue = matchingEnum.display;
      }
    }
  }

  ngOnChanges(changes) {
    this.prepareInputField();
  }

  prepareInputField() {
    if (this.fei.manyToManyPath) {
      this.fei.manyToManyPath = StringUtils.uncapitalize(this.fei.manyToManyPath);
    }

    if (this.fei.cityHelper || this.fei.zipCodeHelper) {
      const service = new DynamicCrudServiceGeneric(this.environment, this.http);
      service.setControllerName('frenchCity');
      this.fei.observableSource = service.getAllFromTerm;
      this.fei.lockDirectAdd = true;
    } else if (this.feiService.isTypeFromApi(this.fei)) {
      this.loadSource();
    } else {
      this.updateField();
    }

    if (this.fei.displayFunction) {
      this.formatters.display = this.fei.displayFunction;
    }
  }

  isTextEdit(fei: FieldEditorInformation) {
    return this.textEditTypes.indexOf(fei.type) !== -1 && !fei.possibleValues;
  }

  updateField() {
    const type = this.fei.type;

    if (type === CSharpTypes.DateTime && this.object) {
      const val = this.object[this.fei.name];
      if (val) {
        const dateVal = new Date(val);
        this.object[this.fei.name] = dateVal;
      } else {
        this.object[this.fei.name] = null;
      }

      if (this.fei.dateWithTime && val) {
        this.timeValue = `${this.padStart(getHours(val))}:${this.padStart(getMinutes(val))}`;
      }
    }

    this.ready = true;
  }

  padStart(s) {
    if (s.length === 1) {
      return `0${s}`;
    }

    return s;
  }

  updateTime(event) {
    let value = this.feiService.get(this.object, this.fei);
    const val = event.target.value;
    value = setHours(value, val.split(':')[0]);
    value = setMinutes(value, val.split(':')[1]);
    this.object[this.fei.name] = value;
  }

  loadSource() {
    let type = this.fei.type;
    let mtmPath = this.fei.manyToManyPath;

    if (mtmPath) {
      mtmPath = StringUtils.uncapitalize(mtmPath);
      this.fei.manyToManyPath = mtmPath;
      type = mtmPath;
    }
    let srv: DynamicCrudServiceInterface = new DynamicCrudServiceGeneric(this.environment, this.http);
    if (this.environment.useCache) {
      srv = new DynamicCrudServiceGenericCache(this.environment, this.http);
    }

    if (this.fei.lookupProperty && this.fei.lookupCollection && !this.fei.observableSource) {
      this.setLookupObservable(srv);
    } else {
      if (!this.fei.observableSource) {
        srv.setControllerName(type);
        this.fei.service = srv;
        this.fei.observableSource = this.fei.service.getAllFromTerm;
        this.updateField();
      }
    }

    if (this.fei.oneToOne && !this.object[this.fei.name]) {
      this.object[this.fei.name] = {};
    }
  }

  togglePanel(fei: FieldEditorInformation) {
    if (!fei.togglePanel) {
      fei.togglePanel = false;
    }
    fei.togglePanel = !fei.togglePanel;
  }

  prepareDeleteOrArchive() {
    this.opendelete = true;
  }

  unarchive() {
    this.fei.service.unarchive(this.object.id).subscribe(x => {
      this.object = x;
    });
  }

  private setLookupObservable(srv: DynamicCrudServiceInterface) {
    if (!this.object || !this.schema) { return; }
    const lookupPropertyName = StringUtils.uncapitalize(this.fei.lookupProperty);
    const lookupProperty = this.schema.fieldEditorInfoAttributes.find(x => x.name === lookupPropertyName);
    if (lookupProperty) {
      this.fei.lookupName = lookupProperty.displayName;
      const lookuptype = lookupProperty.type;
      srv.setControllerName(lookuptype);
      const service = srv;
      const lookupObject = this.object[lookupPropertyName];

      if (lookupObject) {
        this.setupCollectionInformation(service, lookupObject);
      } else {
        this.fei.observableSource = null;
        this.updateField();
      }

    } else {
      console.warn(`cannot find property ${lookupPropertyName} from schema. Does it have a FieldEditorInfoAttribute ?`, this.schema);
    }
  }

  private setupCollectionInformation(service: DynamicCrudServiceInterface, lookupObject: any) {
    // TEST KEVIN
    if (this.fei.observableSource) { return; }

    service.setupCollectionGetter(lookupObject.id, this.fei.lookupCollection);
    this.fei.observableSource = service.getCollectionForTerm;
    service.getSchema().subscribe(subSchema => {
      const lookupCollectionName = StringUtils.uncapitalize(this.fei.lookupCollection);
      const subFei = subSchema.fieldEditorInfoAttributes.find(x => x.name === lookupCollectionName);
      const subService = new DynamicCrudServiceGeneric(this.environment, this.http);
      if (subFei) {
        subService.setControllerName(subFei.type);
        const lookupDirectConf = {};
        lookupDirectConf[StringUtils.uncapitalize(this.fei.lookupReverse)] = lookupObject;
        this.fei.lookupDirectConf = lookupDirectConf;
        this.fei.service = subService;
        this.updateField();
      } else {
        console.warn(`cannot find property ${lookupCollectionName} from schema.
              Does it have a FieldEditorInfoAttribute ?`, subSchema);
      }
    });
  }

  isDate(str: string) {
    return isDate(str);
  }

  formattedDate(d = new Date) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return `${day}/${month}/${year}`;
  }

  calculate(changedFei: FieldEditorInformation, event = null) {
    this.cityService.handleCityAndZipHelper(this.object, this.feis, changedFei);
    this.fieldChanged.emit(changedFei);
  }

  updateDate($event, fei) {
    if ($event) {
      const val = $event.target ? $event.target.value : $event;
      const currentValue: Date = this.feiService.get(this.object, fei);
      let hours = 0;
      let minutes = 0;
      if (currentValue) {
        minutes = currentValue.getMinutes();
        hours = currentValue.getHours();
      }
      const dateVal = val;
      if (isDate(dateVal)) {
        dateVal.setHours(hours);
        dateVal.setMinutes(minutes);
        this.object[fei.name] = dateVal;
      }
      this.calculate(fei);
    }
  }

  clearDateTime(fei) {
    this.feiService.clear(this.object, fei);
    this.fieldChanged.emit(fei);
    this.feiService.refresh(fei);
  }

  copyObjectInLocal(obj) {
    if (!obj) { return; }
    if (obj.id) {
      this.mode = EditMode.Edit;
    }
    this.injectedValue = Object.assign({}, obj);
    this.clone = Object.assign({}, obj);
  }

  manyToManyCallback(evt, fei) {
    if (!evt.id) { return; }
    const el = {};
    el[fei.manyToManyPath] = {
      id: evt.id
    };

    const patch = [{
      'op': 'add',
      'path': fei.name,
      'value': el
    }];

    this.baseService.patch(this.object.id, patch).subscribe(
      success => {
        this.reloadCollection(fei.name);
      },
      error => {
        this.reloadCollection(fei.name);
      });

    this.valueChanged.emit({ object: this.object, fei: fei });
    this.fieldChanged.emit(fei);
  }

  getInputType(fei: FieldEditorInformation) {
    switch (fei.type) {
      case 'Int32':
        return 'number';
      case 'Boolean':
        return 'checkbox';
      default:
        return null;
    }
  }

  closeAddModal() {

  }

  reloadCollection(name) {
    this.completeValue = '';
    if (this.object.id) {
      this.baseService.get(this.object.id).subscribe(sub => {
        sub[name] = this.reindexTable(sub[name]);
        this.object[name] = sub[name];
      });
    }
  }

  remove(sub: any, fei: { name: string; }) {
    const patch = [{
      'op': 'remove',
      'path': fei.name + '/' + sub.index,
    }];

    this.baseService.patch(this.object.id, patch).subscribe(
      () => {
        this.reloadCollection(fei.name);
      });
    this.fieldChanged.emit(fei);
  }

  reindexTable(table) {
    if (table && Array.isArray(table) && table.length > 0) {
      const res = [];

      for (let i = 0; i < table.length; i++) {
        const el = table[i];
        el.index = i;
        res.push(el);
      }

      res.sort(function (a, b) {
        return a.display - b.display;
      });
      return res;
    }

    return table;
  }

  // autocomplete
  dislayAddPopupFor(fei) {
    if (!fei.service) {
      fei.service = this.serviceProvider.getInstance(fei.type);
    }
    fei.showModal = true;
  }

  handleObjectAdded(event: LoadableObject, fei: FieldEditorInformation) {
    fei.showModal = false;
    this.object[fei.name] = event;
    const res = new ValueChangedModel();
    res.fei = fei;
    res.object = event;
    this.valueChanged.emit(res);
  }

  emptyField(fei) {
    this.object[fei.name] = null;
    this.valueChanged.emit(fei);
  }

  onRichTextChange({ editor }: ChangeEvent, fei: FieldEditorInformation) {
    this.feiService.set(this.object, fei, editor.getData());
  }
}
