import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {


  transform(array, obj, orderBy, asc = true) {
    if (!orderBy || orderBy.trim() === '') {
      return array;
    }

    // ascending
    if (asc) {
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item1[obj][orderBy], item2[obj][orderBy]);
      });
    } else {
      // not asc
      return Array.from(array).sort((item1: any, item2: any) => {
        return this.orderByComparator(item2[obj][orderBy], item1[obj][orderBy]);
      });
    }

  }

  orderByComparator(a: any, b: any): number {
    if (!a || !b) {
      if (!a) {
        return -1;
      } if (!b) {
        return 1;
      }
      return 0;
    }

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      // Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) { return -1; }
      if (a.toLowerCase() > b.toLowerCase()) { return 1; }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) { return -1; }
      if (parseFloat(a) > parseFloat(b)) { return 1; }
    }

    return 0; // equal each other
  }
}
