import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { ServiceProvider } from 'capcod-core';

@Component({
  selector: 'capcod-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordUpdateComponent implements OnInit {
  @Input() user: any;
  newPassword: string;
  userService;

  constructor(private serviceProvider: ServiceProvider, private snotifyService: SnotifyService) {
    this.userService = serviceProvider.getInstance('User');
  }

  ngOnInit() {

  }

  updatePassword() {
    this.userService.patch(this.user.id, [
      { path: 'passwordHash', value: this.newPassword, op: 'replace' }
    ]).subscribe(
      succes => this.snotifyService.success('Le mot de passe a été mis à jour'),
      error => this.snotifyService.error('une erreur est survenue')
    );
  }
}
