import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { FilterService, FeiService, Filters, Schema, CustomFilter } from 'capcod-core';

@Component({
  selector: 'capcod-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() filter: Filters;
  @Input() schema: Schema;
  @Input() interface: Schema;
  @Output() filterChanged = new EventEmitter<Filters>();
  @Input() hideActiveOnly: boolean;
  @Input() customFilters: CustomFilter[] = [];
  @Input() customOnly = false;
  filterHub = {};
  filterId = UUID.UUID();

  constructor(private feiService: FeiService, private filterService: FilterService) {
  }

  ngOnInit() {
    this.filter = this.filterService.getFiltersFromLocalStorage();
    if (this.filter && this.customFilters) {
      this.customFilters = this.checkFilterableProperties();
      this.filter.customFilters = this.customFilters;
      this.customFilters.forEach(el => {
        el.value = el.default;
      });
    }
  }

  checkFilterableProperties(): CustomFilter[] {
    const filterableProperties = this.schema.fieldEditorInfoAttributes.filter(x => x.filterable);
    const filters = this.customFilters;
    filterableProperties.forEach(fp => {
      const customPropery = new CustomFilter();
      customPropery.display = fp.displayName;
      customPropery.type = fp.type;
      customPropery.fei = fp;
      customPropery.fromApi = this.feiService.isTypeFromApi(fp);
      filters.push(customPropery);
    });
    return filters;
  }

  refresh() {
    this.filterService.set(this.filter);
    if (this.filter.term) {
      this.filter.term = this.filter.term.trim();
    }
    this.filter.customPayLoad = this.filterHub;
    this.filterChanged.emit(this.filter);
  }

  isActive() {
    if (this.hideActiveOnly) { return false; }

    if (!this.schema || !this.schema.interface) {
      return false;
    }
    return this.schema.interfaces.includes('IActiveObject') || this.schema.interfaces.includes('IArchiveObject');
  }

  resetFilter() {
    this.filter = new Filters();
    localStorage.removeItem(this.filterService.getLocalStorageKey());
    this.filterChanged.emit(this.filter);
  }
}
