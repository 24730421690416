export class TimeTrackedField {
  property: string;
  displayName: string;
  isDate: boolean;

  constructor(property: string, displayName: string, isDate: boolean = false) {
    this.property = property;
    this.displayName = displayName;
    this.isDate = isDate;
  }
}
