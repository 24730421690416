import { Injectable } from '@angular/core';
import { eachDayOfInterval, getISOWeek } from 'date-fns';
import { IDateOptions } from '../classes/utils/idate-options';
import { Utils } from '../classes/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  static options: IDateOptions = { weekStartsOn: 1 };

  constructor() { }

  public eachWorkingDays(start: Date, end: Date): Date[] {
    return eachDayOfInterval({ start, end });
  }

  public getWeekNumbers(start: Date, end: Date): number[] {
    return eachDayOfInterval({ start, end }).map(x => getISOWeek(x)).filter(Utils.unique);
  }
}
