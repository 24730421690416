import { Component, OnInit, Input } from '@angular/core';
import { StringUtils, FieldEditorInformation, FeiService } from 'capcod-core';

@Component({
  selector: 'capcod-value-templater',
  templateUrl: './value-templater.component.html',
  styleUrls: ['./value-templater.component.scss']
})
export class ValueTemplaterComponent implements OnInit {
  @Input() fei: FieldEditorInformation;
  @Input() object: any;
  fieldValue: any;

  constructor(private feiService: FeiService) { }

  ngOnInit() {
    this.fieldValue = this.feiService.get(this.object, this.fei);
    if (this.fei.manyToManyPath) {
      this.fei.manyToManyPath = StringUtils.uncapitalize(this.fei.manyToManyPath);
    }
    if (this.fei.possibleValues) {
      this.fieldValue = this.fei.possibleValues.findIndex(x => x.value === this.fieldValue);
    }
  }
}
