import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { startOfToday, startOfWeek, endOfWeek, subWeeks, addWeeks } from 'date-fns';
import { GenericGanttConfig } from '../../../classes/gantt/generic-gantt-config';
import { DateService, Week } from 'capcod-core';

@Component({
  selector: 'capcod-generic-gantt',
  templateUrl: './generic-gantt.component.html',
  styleUrls: ['./generic-gantt.component.scss']
})
export class GenericGanttComponent implements OnInit {
  @Input() config: GenericGanttConfig;
  @Output() inputValueChanged = new EventEmitter<any>();
  @Output() deadlineValueChanged = new EventEmitter<any>();

  today: Date;
  dates: Date[];
  selectedDate: Date;
  isWithPercentage = false;
  numberOfWeeks = 3;
  numbersOfWeeks = [1, 3, 5, 7];

  constructor(private dateService: DateService) { }

  ngOnInit(): void {
    this.numberOfWeeks = this.config.defaultSelectedWeek;
    this.numbersOfWeeks = this.config.weekSelectionChoices;
    this.today = startOfToday();
    this.changeWeek(new Week(new Date()));
  }

  changeWeek(week: Week): void {
    this.selectedDate = new Date(week.firstDayOfWeek);
    const weeksToAdd = this.getWeeksToAdd();
    const firstDayOfWeek = startOfWeek(this.selectedDate, DateService.options);
    const lastDayOfWeek = endOfWeek(this.selectedDate, DateService.options);
    const firstDayOfPreviousWeek = subWeeks(firstDayOfWeek, weeksToAdd);
    const lastDayOfNextWeek = addWeeks(lastDayOfWeek, weeksToAdd);
    this.dates = this.dateService.eachWorkingDays(firstDayOfPreviousWeek, lastDayOfNextWeek);
  }

  getWeeksToAdd(): number {
    const evenNumber = this.numberOfWeeks % 2 === 0 ? this.numberOfWeeks : this.numberOfWeeks - 1;
    return evenNumber / 2;
  }

  changeNbOfWeeks(nbOfWeek: number) {
    this.numberOfWeeks = nbOfWeek;
  }

  valueChanged(event) {
    this.inputValueChanged.next(event);
  }

  deadlineChanged(event) {
    this.deadlineValueChanged.emit(event);
  }
}
