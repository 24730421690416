import { FieldEditorInformation } from '../classes/api/FieldEditorInformation';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeiService {
  getFei(feis: FieldEditorInformation[], feiName: string) {
    return feis.find(x => x.name === feiName);
  }

  hide(feis: FieldEditorInformation[], feiName: string) {
    const fei = this.getFei(feis, feiName);
    if (fei) {
      fei.hidden = true;
    }
  }

  show(feis: FieldEditorInformation[], feiName: string) {
    const fei = this.getFei(feis, feiName);
    if (fei) {
      fei.hidden = false;
    }
  }

  hideInTable(feis: FieldEditorInformation[], feiName: string) {
    const fei = this.getFei(feis, feiName);
    if (fei) {
      fei.tableDisplay = false;
    }
  }

  get(object: any, fei: FieldEditorInformation) {
    return object[fei.name];
  }

  set(object: any, fei: FieldEditorInformation, value: any) {
    object[fei.name] = value;
  }

  isTypeFromApi(fei: FieldEditorInformation) {
    return fei.cityHelper || fei.zipCodeHelper || (
      FieldEditorInformation.primitiveTypes.indexOf(fei.type) === -1 && !fei.possibleValues
    );
  }

  clear(object: any, fei: any) {
    object[fei.name] = null;
  }

  refresh(fei: any) {
    fei.reload = true;
    setTimeout(() => {
      fei.reload = false;
    }, 1);
  }

  buildLink(fei: FieldEditorInformation, object: any): string {
    let link = fei.link;
    const propValue = this.get(object, fei);
    if (propValue && propValue.display) {
      link = link.replace(`{id}`, propValue.id);
    } else {
      link = link.replace(`{id}`, object.id);
    }
    // Object.keys(object).forEach(key => {
    //     link = link.replace(`{${key}}`, this.get(object, fei));
    // })

    return link;
  }

  getLastVisible(feis: FieldEditorInformation[]) {
    const visibleFeis = feis.filter(x => !x.hidden);
    return visibleFeis[visibleFeis.length - 1];
  }
}
