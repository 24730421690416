import { Component, OnInit, Input } from '@angular/core';
import { ServiceProvider } from 'capcod-core';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'capcod-edit-in-place',
  templateUrl: './edit-in-place.component.html',
  styleUrls: ['./edit-in-place.component.scss']
})
export class EditInPlaceComponent implements OnInit {
  @Input() serviceName: string;
  @Input() propertyName: string;
  @Input() object: any;
  service: any;

  constructor(private serviceProvider: ServiceProvider, private snotifyService: SnotifyService) {

  }

  ngOnInit(): void {
    this.service = this.serviceProvider.getInstance(this.serviceName);
  }


  updateField(event) {
    this.service.patch(this.object.id, [{ path: this.propertyName, op: 'replace', value: event.target.value }]).subscribe({
      next: () => {
        this.snotifyService.success(`le champ à bien été mis à jour.`);
      }
    });
  }

}
