import { ClarityHttpService } from './clarity-http.service';
import { Inject, Injectable } from '@angular/core';
import { DynamicCrudServiceGeneric } from './DynamicCrudServiceGeneric';
import { DynamicCrudServiceGenericCache } from './dynamic-crud-service-generic-cache';
import { DynamicCrudServiceInterface } from './DynamicCrudServiceInterface';

@Injectable({
    providedIn: 'root'
})
export class ServiceProvider {
    constructor(@Inject('env') protected environment, protected http: ClarityHttpService) {
    }

    getInstance(controllerName: string): DynamicCrudServiceInterface {
        let service: DynamicCrudServiceInterface = new DynamicCrudServiceGeneric(this.environment, this.http);
        if (this.environment.useCache) {
            service = new DynamicCrudServiceGenericCache(this.environment, this.http);
        }
        service.setControllerName(controllerName);
        return service;
    }
}
