import * as jwt_decode from 'jwt-decode';

export class GuardHelper {
  static roleClaimPath = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  public static hasRole(roles: string[]) {
    const userRoles = this.getRoles();
    if (!userRoles) { return false; }

    let res = false;
    roles.forEach(checkedRole => {
      if (userRoles && userRoles.includes(checkedRole)) {
        res = true;
      }
    });
    return res;
  }

  public static getRoles(): string {
    const jwt = localStorage.getItem('token');
    const decodedJwtData = jwt_decode(jwt);
    return decodedJwtData[this.roleClaimPath];
  }
}
