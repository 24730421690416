import { FieldEditorInformation } from './FieldEditorInformation';
import { CapcodClassConfig } from './CapcodClassConfig';
export class GroupInformation {
    firstPropertyName: string;
    lastPropertyName: string;
    header: string;
}
export class Schema {
    typeName: string;
    type: any;
    interface: string;
    interfaces: string;
    fieldEditorInfoAttributes: FieldEditorInformation[];
    classConfig: CapcodClassConfig;
    groupInformation: GroupInformation[];
}
