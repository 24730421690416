import { Injectable } from '@angular/core';
import { ClarityAuthService } from '../../services/clarity-auth.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AccountService } from '../../services/account.service';

@Injectable({
    providedIn: 'root'
})
export class LoginConfig {
    constructor(public authService: ClarityAuthService, public accountService: AccountService,
        public router: Router, public snotifyService: SnotifyService) {

    }
}
