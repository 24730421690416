import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StringUtils, FieldEditorInformation, CrudServiceGeneric, FeiService } from 'capcod-core';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'capcod-cell-templater',
  templateUrl: './cell-templater.component.html',
  styleUrls: ['./cell-templater.component.scss']
})
export class CellTemplaterComponent implements OnInit {
  @Input() fei: FieldEditorInformation;
  @Input() object: any;
  @Input() service: CrudServiceGeneric;
  @Output() valueChanged = new EventEmitter();
  originalValue: string;
  link: string;

  constructor(private sno: SnotifyService, private feiService: FeiService) {

  }

  ngOnInit() {
    if (this.fei.link) {
      this.link = this.feiService.buildLink(this.fei, this.object);
    }
    if (this.fei.manyToManyPath) {
      this.fei.manyToManyPath = StringUtils.uncapitalize(this.fei.manyToManyPath);
    }

    if (this.fei.tableEdit && !this.service) {
      console.warn(`You need to inject [service] in [capcod-cell-templater] to use inline editing`);
    }
    this.originalValue = this.feiService.get(this.object, this.fei);
  }

  onFieldChange($event) {
    const nValue = this.feiService.get(this.object, this.fei);

    if (nValue !== this.originalValue) {
      this.service.patch(this.object.id, [{
        path: $event.name, op: 'replace', value: this.object[$event.name]
      }]).subscribe(success => {
        this.sno.success(`Champ ${$event.displayName} mis à jour`);
        this.valueChanged.emit({ fei: $event, object: this.object });
      }, error => {
        this.sno.error(`Une erreur s'est produite pendant la mise à jour du champ (${$event.displayName})`);
      });
    }
  }
}
