import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WebcamInitError, WebcamImage, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'capcod-webcam-manager',
  templateUrl: './webcam-manager.component.html',
  styleUrls: ['./webcam-manager.component.scss']
})
export class WebcamManagerComponent implements OnInit {
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public pictureWasTaken = false;
  public videoOptions: MediaTrackConstraints = {
    facingMode: 'environment'
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;
  public displayedImage: string;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  @Output() pictureTaken = new EventEmitter<any>();

  constructor(private snotify: SnotifyService) {

  }

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.displayedImage = webcamImage.imageAsDataUrl;
    this.pictureWasTaken = true;
  }

  public saveImage() {
    this.pictureTaken.emit(this.webcamImage);
    this.deleteImage();
  }

  public deleteImage() {
    this.pictureWasTaken = false;
    delete this.webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}
