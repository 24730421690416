import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { UploadEvent, FileSystemFileEntry, FileSystemDirectoryEntry, UploadFile } from 'ngx-file-drop';
import { CrudServiceGeneric, Schema, FeiService, FieldEditorInformation, ClarityHttpService } from 'capcod-core';
import { FileService } from '../../services/file.service';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'capcod-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit, OnChanges {
  @Input() objectId: string;
  @Input() service: CrudServiceGeneric;
  @Input() title = 'Déposez vos fichiers ici';
  @Input() propertyName: string;
  @Input() cardTitle = 'Fichiers';
  @Input() allowCamera = false;
  @Input() withImagePreview = false;

  @Output() fileDropped = new EventEmitter<any>();
  @Output() fileDeleted = new EventEmitter<any>();
  @Output() fileAdded = new EventEmitter<any>();

  singleFile = false;
  downloadState = ClrLoadingState.DEFAULT;
  savedFiles: any[];
  currentFileName: string;
  deleteConfirm: boolean;
  public openWebCam = false;
  previewUrl: any;

  private schema: Schema;
  fileBeingDropped = false;

  constructor(private snotifyService: SnotifyService, private feiService: FeiService, private fileService: FileService,
    private http: ClarityHttpService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.load();
  }

  load() {
    if (this.service && this.objectId) {
      this.service.getFiles(this.objectId, this.propertyName).subscribe((success: any) => {
        this.savedFiles = success;
        this.service.getSchema().subscribe(schema => {
          this.schema = schema;
          const propertyFei = schema.fieldEditorInfoAttributes.find((x: FieldEditorInformation) => x.sortName === this.propertyName);
          if (propertyFei) {
            this.singleFile = !propertyFei.type.includes('List');
          }
          this.preparePreview(success);
        });
      });
    }
  }

  private preparePreview(success: any) {
    if (success && this.singleFile && success.length > 0) {
      if (!success[0].contentType.includes('image')) { return; }
      const file = success[0];
      this.download(this.objectId, file.name).subscribe(x => {
        const blob = new Blob([x], { type: file.contentType });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          this.previewUrl = this.sanitizer.bypassSecurityTrustUrl(base64data.toString());
        };
      });
    }
  }

  public dropped(event: UploadEvent) {
    this.fileBeingDropped = true;
    this.fileDropped.emit(this.fileBeingDropped);
    if (this.singleFile) {
      this.dropSingleFileConfig(event);
    } else {
      this.droppeMultipleFilesConfig(event);
    }
  }

  private dropSingleFileConfig(event: UploadEvent) {
    const droppedFile = event.files[0];
    if (droppedFile.fileEntry.isFile) {
      if (this.savedFiles.length !== 0) {
        this.currentFileName = this.savedFiles[0].name;
        this.deleteFile();
      }
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      this.uploadFileEntry(fileEntry);
    }
  }

  private droppeMultipleFilesConfig(event: UploadEvent) {
    for (const droppedFile of event.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        this.uploadFileEntry(fileEntry);
      } else {
        const directoryEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        directoryEntry.createReader().readEntries((entries: FileSystemFileEntry[]) => {
          const files = this.GetFiles(entries, droppedFile);
          this.service.uploadFiles(this.objectId, files, this.propertyName).subscribe(success => {
            this.snotifyService.success('Fichiers ajoutés avec succès');
            this.fileBeingDropped = false;
            this.fileAdded.emit(success);
            this.load();
          });
        });
      }
    }
  }

  private GetFiles(entries: FileSystemFileEntry[], droppedFile: UploadFile): File[] {
    const files = [];
    entries.forEach(entry => {
      const fileEntry = entry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        files.push(file);
      });
    });
    return files;
  }

  private uploadFileEntry(fileEntry: FileSystemFileEntry) {
    fileEntry.file((file: File) => {
      this.uploadFile(file);
    });
  }

  private uploadFile(file: File) {
    this.service.uploadFile(this.objectId, file, this.propertyName).subscribe(success => {
      this.snotifyService.success('Fichier ajouté avec succès');
      this.fileBeingDropped = false;
      this.fileAdded.emit(success);
      this.load();
    });
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  downloadFile(name: string) {
    this.downloadState = ClrLoadingState.LOADING;
    this.service.download(this.objectId, name).subscribe({
      next : () => {
        this.downloadState = ClrLoadingState.SUCCESS;
      }
    });
  }

  prepareDeleteFile(name: string) {
    this.currentFileName = name;
    this.deleteConfirm = true;
  }

  deleteFile() {
    this.service.deleteFile(this.objectId, this.propertyName, this.currentFileName).subscribe(x => {
      this.snotifyService.success('Fichier supprimé avec succès');
      this.fileDeleted.emit(x);
      this.load();
    }, error => {
      this.snotifyService.success(`Une erreur est survenue`);
    });
  }

  public onPictureTaken($event) {
    this.openWebCam = false;
    this.savePicture($event);
  }

  public savePicture(event) {
    const blob = this.fileService.dataURItoBlob(event._imageAsDataUrl);
    this.uploadFile(this.fileService.blobToFile(blob, `IMG${new Date().toISOString().split('T', 1)}.jpg`));
  }

  public openWebcam() {
    this.openWebCam = true;
  }

  public download(id: string, fileName: string): Observable<Blob> {
    const path = `${this.service.baseurl}/${id}/download/${fileName}`;
    return this.http.get(path, { withCredentials: true, responseType: 'blob' });
  }
}
