import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterService } from '../classes/utils/filter.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ClarityAuthService {
    filterService;
    usernameKey;
    passwordKey;
    username = window.localStorage.getItem(this.usernameKey);
    password = window.localStorage.getItem(this.passwordKey);

    constructor(@Inject('env') private env, private http: HttpClient, private router: Router) {
        this.filterService = new FilterService();
        this.usernameKey = `${this.env.applicationName}_username`;
        this.passwordKey = `${this.env.applicationName}_password`;
    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    public logout() {
        this.clearAuthInfo();
    }

    forgottenPassword(email: string): Observable<any> {
        return this.http.get(`${this.env.apiUrl}/account/forgottenpassword/${email}`);
    }

    canLog() {
        this.username = window.localStorage.getItem(this.usernameKey);
        this.password = window.localStorage.getItem(this.passwordKey);
        return this.username && this.password;
    }

    logIfRemembered(): Observable<any> {
        if (this.canLog()) {
            const prevUrl = window.localStorage.getItem('prevUrl');
            if (prevUrl && !window.localStorage.getItem('token')) {
                window.location.href = prevUrl;
            }

            return this.doLogin({ username: this.username, password: this.password });
        } else {
            throw new Error('cannot log');
        }

    }

    login(payload: any): Observable<any> {
        if (payload.rememberme) {
            window.localStorage.setItem(this.usernameKey, payload.username);
            window.localStorage.setItem(this.passwordKey, payload.password);
        } else {
            this.clearAuthInfo();
        }

        return this.doLogin(payload);
    }

    private clearAuthInfo() {
        window.localStorage.removeItem(this.usernameKey);
        window.localStorage.removeItem(this.passwordKey);
        window.localStorage.removeItem('token');
    }

    private doLogin(payload: any): Observable<any> {
        return new Observable((obs) => {
            return this.http.post(`${this.env.apiUrl}/token/create`, payload, { withCredentials: true })
                .subscribe((data: any) => {
                    if (data.token) {
                        localStorage.setItem('token', data.token);
                        this.filterService.removeAllStockedFilters();
                        obs.next();
                    }
                }, error => {
                    obs.error(error);
                });
        });
    }
}
