import { FieldEditorInformation } from './FieldEditorInformation';

export class CustomFilter {
    name: string;
    display: string;
    type: string;
    default: any;
    value: any;
    fromApi: boolean;
    fei: FieldEditorInformation;
}
