import { ClrLoadingState } from '@clr/angular';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { OnInit } from '@angular/core';
import { ClarityAuthService, AccountService, LoginConfig } from 'capcod-core';

export abstract class GenericLoginComponent implements OnInit {
    showerror = false;
    loginBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
    protected authService: ClarityAuthService;
    protected accountService: AccountService;
    protected router: Router;
    protected snotifyService: SnotifyService;

    constructor(protected loginConfig: LoginConfig) {
        this.authService = loginConfig.authService;
        this.accountService = loginConfig.accountService;
        this.router = loginConfig.router;
        this.snotifyService = loginConfig.snotifyService;
    }

    ngOnInit() {
        if (this.authService.canLog()) {
            this.authService.logIfRemembered().subscribe(
                success => {
                    this.onLoginSuccess();
                },
                errors => {
                    this.onLoginError();
                }
            );
        }
    }

    login(value) {
        this.loginBtnState = ClrLoadingState.LOADING;
        this.authService.login(value).subscribe(success => {
            this.onLoginSuccess();
        }, error => {
            this.onLoginError();
        });
    }

    protected onLoginSuccess(): any {
        this.showerror = false;
        this.accountService.setRoles().subscribe(() => {
            this.afterLogin();
            this.loginBtnState = ClrLoadingState.SUCCESS;
        });
    }

    abstract afterLogin();

    protected onLoginError(): any {
        this.showerror = true;
        this.snotifyService.error('Impossible de vous connecter à l\'application');
        this.loginBtnState = ClrLoadingState.ERROR;
    }

    forgottenPassword(username) {
        this.authService.forgottenPassword(username).subscribe(
            success => {
                this.snotifyService.success('Un mail vous a été envoyé.');
            },
            errors => {
                this.snotifyService.error(`Cette adresse mail n'est pas reconnue`);
            }
        );
    }
}
