import { CrudServiceGeneric } from '../classes/CrudServiceGeneric';
import { ClarityHttpService } from './clarity-http.service';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DynamicCrudServiceInterface } from './DynamicCrudServiceInterface';

@Injectable({
    providedIn: 'root'
})
export class DynamicCrudServiceGeneric extends CrudServiceGeneric implements DynamicCrudServiceInterface {
    collection: string;
    key: string;

    constructor(@Inject('env') protected environment, protected http: ClarityHttpService) {
        super(environment, http, null);
    }

    setControllerName(name: string) {
        this.baseurl = `${this.environment.apiUrl}/${name}`;
        this.controllerName = name;
    }

    setupCollectionGetter(key: string, colName: string) {
        this.key = key;
        this.collection = colName;
    }

    getCollectionForTerm = (keyword: any): Observable<any[]> => {
        if (!this.key || !this.collection) {
            throw new Error('You must set key and collection before calling this method (with setupCollectionGetter(key, collection))');
        }

        return this.http.get(`${this.baseurl}/collection/${this.key}/${this.collection}`,
            { withCredentials: true }).pipe(map((x: any) => x.result));
    }
}


