import { Buildable } from 'capcod-core';
import { GanttRowModel } from './gantt-row-model';

export class GenericGanttConfig extends Buildable {
  rows: GanttRowModel[];
  fullSize = false;
  weekSelectionChoices = [1, 3, 5, 7, 9, 14, 20];
  defaultSelectedWeek = 3;
  dateFormat = 'EEEEE';

  constructor(partial: Partial<GenericGanttConfig>) {
    super(partial);
    this.build(partial);
  }
}
